export default {
	"Already a member?": "Oletko jo Jäsen?",
	"Login": "Kirjaudu",
	"Log out": "Kirjaudu ulos",
	"Your current status": "Tämänhetkinen statuksesi",
	"BASIC": "PERUSTILAUS",
	"Basic": "Basic",
	"Bronze": "Pronssi",
	"Elite": "Eliitti",
	"VIP": "VIP",
	// "Join Us": "Entra ahora",
	// "Find people near you": "Encuentra gente cerca de ti",
	"Join now": "Etsi nyt",
	"I am a": "Haku",
	"Email": "Sähköposti",
	"It will not be published": "Sitä ei julkisteta",
	"E-mail address": "Kirjoita sähköpostiosoittees",
	"SEARCH NOW": "LITTY ILMAISEKSI",
	"I confirm that I am over 18 years old": "Vakuutan että olen yli 18 vuotias",
	// "Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "No lo pienses más y entra al fenómeno social del momento. Cada día miles de personas como tú se unen a nosotros.",
	"Information": "Informaatio",
	"All rights reserved.": "Kaikki oikeudet pidätetään.",
	"SUBSCRIPTION": "TILAUS",
	"Other": "Muu",
	"All fields are required": "Kaikki kentät vaadittu",
	"The fields marked in Red are required. Please fill them to continue.": "Punaisella merkityt kentät ovat pakollisia. Täytä ne jatkaaksesi.",
	"If you did not find a suitable answer please contact us": "Jollet löytänyt sopivaa kysymystä kirjoita meille",
	"Will help us to identify you": "Auttaa meitä tunnistamaan sinut",
	"Invalid Username": "Väärä Käyttäjänimi",
	"Subject": "Aihe",
	"Tell us how can we help you": "Kerro meille kuinka voimme auttaa",
	"Please enter subject": "Lisää aihe",
	"Please enter at least 10 characters": "Lisää vähintään 10 merkkiä",
	"Connected to your account": "Liitetty tiliisi",
	"Repeat Email": "Toista Sähköposti",
	"Emails did not match": "Sähköpostia ei tunnistettu",
	"Message": "Viestit",
	"No Recent Activity": "Ei Viimeaikaista Aktiviteettia",

	//route labels
	"Home": "Etusivu",
	"Contact": "Ota yhteyttä",
	"Support": "Tuki",
	"Affiliates": "Kumppanit",
	"Privacy": "Yksityisyys",
	"Terms": "Käyttöehdot",

	//routes uri
	"/login": "/kirjaudu-sisaan",
	"/contact": "/ota-yhteytta",
	"/support": "/ota-yhteytta",
	"/affiliates": "/yhteistyokumppanit",
	"/privacy": "/yksityisyys",
	"/terms-and-conditions": "/kayttoehdot",
	"/cookie-policy": "/evastekaytanto",
	"/subscription/exit": "/maksu/poistuminen",
	"/purchase-confirmation": "/ostosvahvistus",
	"/page/:page_name": "/sivu/:page_name",

	"/dashboard": "/hallintapaneeli",
	"/mobile-dashboard": "/kannykka-hallintapaneeli",
	"/activate-profile/:token": "/aktivoi/:token",
	"/edit-profile": "/muokkaa-profiilia",
	"/profile/:name": "/profiili/:name",
	"/my-picture": "/kuvani",
	"/messages/inbox": "/viestit/saapuneet-viestit",
	"/messages/trash": "/viestit/roskaposti",
	"/messages/send/:name": "/viestit/laheta/:name",
	"/visitor": "/vierailija",
	"/favorite/my": "/suosikki/minun",
	"/favorite/others": "/suosikki/muut",
	"/search": "/haku",
	"/search-result": "/hakutulokset",
	"/settings": "/asetukset",
	"/validate-new-email-address/:token": "/tarkista-uusi-sahkopostiosoite/:token",
	"/subscription": "/maksu",
	"/credits": "/kolikkoa",
	"/elite": "/eliitti",
	"/faq": "/usein-kysyttya",
	"/delete-account": "/poista-kayttaja",

	"/exclusive-promotion": "/erikoistarjous",
	"/account-deleted": "/tili-onnistuneesti-poistettu",
	"/logged-out": "/kirjautunut-ulos",
    "/complete-register/:token" : '/vahvista-rekisterointi/:token',

	//login
	"Login": "Kirjaudu",
	"Enter username or Email": "Lisää käyttäjänimi tai sähköposti",
	"Enter Password": "Lisää salasana",
	"Username": "Käyttäjänimi",
	"Nickname": "Lempinimi",
	"Password": "Salasana",
	"I forgot my password": "Unohdin salasanani",
	"Password Reminder": "Salasanan Muistuttaja",
	"Enter you email": "Lisää sähköpostisi",
	"Your email address": "Sähköpostiosoitteesi",
	"Don't have an account?": "Ei tiliä?",
	"REGISTER": "REKISTERÖIDY",
	"Password sent to your email": "Salasana lähetetty sähköpostiisi",
	"This account has not been validated yet": "Tiliä ei ole vielä aktivoitu",
	"LOGIN USING YOUR CREDENTIALS": "KIRJAUDU KÄYTTÄMÄLLÄ TUNNUSTETIETOJASI",
	"FORGOT YOUR PASSWORD?": "UNOHDITKO SALASANASI?",
	"Login failed": "kirjautuminen epäonnistui",
	"Username or password is incorrect": "Käyttäjänimi tai salasana on väärä",

	"We're sorry!": "Olemme pahoillamme",
	//registration
	"You're almost done!": "Olet melkein valmis!",
	"Please fill your additional account information": "Täydennä tilisi lisäinformaatio",
	"Just one step left!": "Enää yksi kohta jäljellä!",
	"Let us learn more about you. Fill in your personal data": "Kerro meille enemmän itsestäsi. Täytä henkilökohtaiset tietosi",
	"Activate your account now.": "Aktivoi tilisi nyt!",
	"Activate your account now": "Aktivoi tilisi nyt!",
	"Please confirm your e-mail address": "Vahvista sähköpostiosoitteesi",
	"Please fill in the following form to join {site_name} for free": "Täytä seuraava kaavake saadaksesi ilmaisjäsenyyden sivustolla {site_name}",
	"CONTINUE": "JATKA",

	"By clicking on the continue button I agree with the": "Klikkaamalla \“Jatka\” sitoudun",
	"terms": "käyttöehtoihin",
	"and cookies": "ja evästeisiin",

	"This site is protected by reCaptcha and the Google": "Tätä sivua suojaa reCAPTCHA ja sivustolla sovelletaan Googlen",
	"Privacy Policy": "tietosuojakäytäntöä",
	"and": "ja",
	"Terms of Service": "käyttöehtoja",
	"apply.": ".",

	"Personal description": "Henkilökohtainen kuvaus",
	"optional": "vaihtoehtoinen",
	"FINALIZE": "VIIMEISTELE",
	"We have just sent you a confirmation e-mail at": "Olemme juuri lähettäneet sinulle sähköpostivahvistuksen osoitteeseen",
	"To start using our website, please click on the activation link in this email.": "Aloittaaksesi webbisivujemme käytön klikkaa aktivointilinkkiä tässä sähköpostissa.",
	"Click here to verify your email": "Klikkaa tästä vahvistaaksesi sähköpostisi",
	"CLICK TO ACTIVATE": "VAHVISTA TÄSTÄ",
	"CLICK TO ACTIVATE - desktop": "VAHVISTA KLIKKAAMALLA TÄSTÄ",
	"VERIFY MY EMAIL": "VAHVISTA SÄHKÖPOSTISI",
	"Not received any email yet?": "Ei saapuneita sähköposteja?",
	"Please check your": "Tarkista",
	"spam": "roskapostiisi",
	"box and wait at least 10 minutes or re-send activation": "kansiosi ja odota ainakin 10 minuuttia tai uudelleenlähetä aktivointi.",
	"Resend Email": "Uudelleenlähetä Sähköposti",
	"Enter Nickname": "Lisää Lempinimi",
	"Enter Your Name": "Lisää Nimesi",
	"Password Required": "Vaatii salasanan",
	"Enter valid Email": "Kirjoita voimassa oleva sähköposti",
	"Please enter at least 5 characters": "Lisää 5 merkkiä",
	"Enter strong password": "Lisää vaikea salasana",
	"Email not available": "Sähköposti ei käytössä",
	"Nickname not available": "Lempinimi ei ole saatavilla",
	"Maximum 4 number allowed": "Enintään 4 numeroa sallittu",
	"Name should not contain any space, number or special character": "Nimessä ei voi olla välilyöntejä, numeroita tai erikoismerkkejä",
	"Nickname should not contain any space or special characters": "Lempinimessä ei voi olla välilyöntejä tai erikoismerkkejä",
	"Your activation mail has been sent": "Your Aktivointi sähköposti lähetetty",
	"Complete your profile in a minute and start contacting people": "Täydennä profiilisi minuutissa ja tapaa ihmisiä",
	"Complete your profile": "Täydennä profiilisi",
	"Create Profile": "Luo profiili",
	"Your description": "Kuvauksesi",
	"Confirm your Email": "Vahvista sähköpostisi",
	"We have sent you an email.": "Olemme lähettäneet sinulle sähköpostia.",
	"Check your email to complete your registration": "Tarkista sähköpostisi suorittaaksesi rekisteröitymisesi loppuun",
	"and be able to speak with other users.": "ja jutellaksesi muiden käyttäjien kanssa.",
	"If you can not find it look it up in the spam or": "Jollet löydä sitä, tarkista roskapostisi tai",
	"If you have not received it select one of the following options": "Jollei se ole saapunut valitse yksi seuraavista vaihtoehdoista",
	"junk folder": "spam kansiosi",
	"Change Email": "Vaihda Sähköposti",
	"Resend Email": "Uudelleenlähetä Sähköposti",
	"Resend": "Uudelleenlähetä",
	"New Email": "Uusi Sähköposti",
	"Your activation key is invalid": "Aktivointiavaimesi ei ole voimassa",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Ole hyvä ja sisällä '@' sähköpostiosoitteeseesi. '{email}' ei pelkästään riitä.",

	//public pages
	"privacy": "Yksityisyydensuojaan",
	"terms-and-conditions": "käyttöehdot",
	"about-us": "tietoja-meista",

	//forms
	"Name": "Nimi",
	"Marital Status": "Siviilisääty",
	"Date of Birth": "Ikä",
	"Country": "Maa",
	"Location": "Maakunta",
	"Province": "Maakunta",
	"City": "Kaupunki",
	"Save Changes": "Säästä Muutokset",
	"Select from list": "Valitse listalta",
	"Day": "Päivä",
	"Month": "Kuukausi",
	"month": "Kuukausi",
	"Year": "Vuosi",
	"Prefer not to say": "En halua sanoa",
	"Only profiles with photo": "Ainoastaan kuvallisia profiileita",
	"This field is required": "Tämä kenttä on pakollinen",
	"Please update your profile": "Päivitä profiilisi",
	"Profile successfully updated": "Profiili päivitetty onnistuneesti",
	"The fields marked in red are required. Please fill them to continue.": "Punaisella merkityt kentät ovat pakollisia. Täytä ne jatkaaksesi.",
	"Characters left": "Merkkiä jäljellä",
	"Image attached": "Kuva liitetty",
	"The message field is required.": "Viestikenttä on pakollinen.",
	"WITH PICTURE": "KUVALLINEN",
	"all age groups": "Kaikki ikäryhmät",
	"EDIT DESCRIPTION": "MUOKKAA KUVAUSTA",
	"DESCRIPTION": "KUVAUS",
	"FEATURING": "OMINAISPIIRTEET",
	"Please Select": "Valitse",
	"Emails do not match": "Sähköpostia ei tunnistettu",
	"Your request was succesfully sent!": "Viestisi on lähetetty onnistuneesti!",
	"Or send us a message directly": "Tai lähetä meille sähköpostia",

	//user pages
	"My Profile": "Profiilini",
	"Add Photos": "Lisää kuvia",
	"Messages": "Viestit",
	"Favorites": "Suosikit",
	"Search": "Etsi",
	"Settings": "Asetukset",
	"Visits": "Vierailija",
	"Likes": "Minä Suosikkina",
	"Membership": "Jäsenyys",
	"Vip Members": "VIP Jäsenet",
	"Profile_mobile_menu": "Profiili",

	"Account activation successful": "Tilin aktivointi onnistui",
	"My chats": "Chattailyni",

	//user home
	"Quick Search": "Pikahaku",
	"More Options": "Lisää Vaihtoehtoja",
	"I am a": "Haku",
	"Looking for": "Etsin",
	"Age": "Ikä",
	"Search for": "Etsi",
	"All age groups": "Kaikki ikäryhmät",
	"Popular Members": "Suositut Jäsenet",
	"New Members": "Uudet Jäsenet",
	"Find a Match": "Löydä Kumppani",
	"Recent Activity": "Viimeaikainen aktiivisuus",
	"Add Photo": "Lisää kuva",
	"Back": "Takaisin",
	"Become Elite": "Tule Eliitiksi",
	"Upgrade": "TILAA",

	"likes": "HULLAANTUNUT",
	"EDIT MY PROFILE": "MUOKKAA PROFIILIANI",
	"CONFIGURATION": "ASETUKSET",
	"ONLINE SUPPORT": "ONLINE TUKI",

	//user message
	"Trash": "Roskat",
	"Delete Selected": "Poista Valitut",
	"No Messages Available": "Ei saatavilla olevia viestejä",
	"No Messages selected": "Ei Valittuja Viestejä",
	"Back to inbox": "Takaisin postilaatikkoon",
	"Back to messages": "Takaisin viesteihin",
	"Select all": "Valitse kaikki",
	"Deselect all": "Poista kaikki",
	"Next Page": "Seuraava Sivu",
	"Prev Page": "Edelline Sivu",
	"Type your message here": "Kirjoita viestisi tähän",
	"Send message": "Lähetä viesti",
	"Send Icebreaker": "Lähetä Jäänmurtaja",
	"VIEW PROFILE": "KATSO PROFIILI",
	"DELETE": "POISTA",
	"Chat with": "Chattaile, linjoilla nyt",
	"Chat": "Chat",

	//user favorites
	"My Favorites": "Suosikkini",
	"No likes": "Ei Suosikkeja",
	"Favorite added successfully": "Suosikki lisätty onnistuneesti",
	"Favorite deleted successfully": "Suosikki poistettu onnistuneesti",
	"Visit Profile": "Kurkista Profiilia",

	//user visits
	"My Profile Visitors": "Profiilini Vierailijatl",
	"No Visitors": "Ei vierailijoita",

	//user photos
	"My pictures": "Minun kuvani",
	"See my public profile": "Näe julkinen profiilisi",
	"Profile photo": "Profiilikuva",
	"Profile Picture": "Profiilikuva",
	"2MB or lower.": "2MB tai vähemmän.",
	"2MB or lower": "2MB tai vähemmän",
	"Nudes not allowed in this category.": "Alastonkuvat eivät ole sallittuja tässä kategoriassa.",
	"PHOTO ALBUM": "KUVA-ALBUMI",
	"Allowed images 2 megabytes or less": "sallitut kuvat 2 megatavua tai vähemmän",
	"Add picture": "Lisää kuva",
	"Main reasons for rejection": "Hylkäämisperusteet",
	"Are you sure?": "Oletko varma?",
	"Are you sure you want to delete this image?": "Oletko varma että haluat poistaa tämän kuvan?",
	"Close": "Peruuta",
	"Confirm": "Vahvista",
	"Attaching image": "Kuvaa liitetään",
	"The message must be at least 2 characters.": "Viestin pitää olla ainakin 2 merkkiä.",
	"Deleting...": "Poistetaan...",
	"Pictures of underage people": "Kuvassa lapsi",
	"Personal data is visible": "Kuvassa omia tietoja",
	"Fake or stolen pictures": "Kuva väärennetty tai varastettu",
	"Different gender to profile": "Kuvan sukupuoli ei vastaa profiilia",
	"Group pictures": "Ryhmäkuva",
	"Weapons, drugs, violence": "Kuvassa aseita, huumeita tai väkivaltaa",
	"No people visible": "Kuvassa ei henkilöä",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Käymme läpi kuvasi. Kun ne on tarkistettu ne ovat muiden käyttäjien nähtavissä.",

	//user profiles
	"Profile": "Profiili",
	"yrs": "v",
	"years": "vuotta",
	"yr from": "vuotta",
	"Physique": "Fysiikka",
	"Photos": "Kuvat",
	"No Photos": "Ei Kuvia",
	"About Me": "Minusta",
	"Characteristics": "Erityispiirteet",
	"Prefers": "Mieltymykset",
	"Relationship": "Suhde",
	"Shape": "Muoto",

	//user edit profile
	"DESCRIBE YOURSELF": "KUVAILE ITSEÄSI",
	"GENERAL": "YLEINEN",
	"(Will be verified)": "",
	"Sex": "Sukupuoli",
	"Seeking": "Etsin",
	"Finish your profile": "Huomio",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Jatkaaksesi täydennä profiilisi!",

	//search profile
	"Advanced Search": "Tarkennettu Haku",
	"OPEN TO": "MIELTYMYKSET",
	"PREFERENCES": "HALUAN",
	"CHARACTER": "LUONNE",
	"Search Result": "Hakutulos",
	"No Member found": "Ei Löydettyjä Jäseniä",
	"Change Search": "Vaihda hakua",
	"Hair Color": "Hiusten väri",
	"Eye Color": "Silmien väri",
	"Body Type": "Ruumiinrakenne",
	"Height": "Pituus",
	"Weight": "Paino",
	"Smoker": "Tupakointi",
	"Ethnicity": "Etnisyys",
	"From": "Oma",
	"To": "Haun",

	//settings
	"Change E-mail address": "Vaihda Sähköpostiosoite",
	"Your email address has been successfully updated": "Sähköpostiosoitteesi on päivitetty onnistuneesti",
	"Password": "Salasana",
	"Account Status": "Tilin Tyyppi",
	"Cancel": "Jatka",
	"Delete Account": "Poista tili",
	"Notifications": "Ilmoitukset",
	"Receive all notifications individually": "Vastaanota yksittäiset ilmoitukset",
	"Receive one daily email with all my notifications": "Vastaanota yksi sähköposti päivässä, johon on kerätty ilmoitukset",
	"Receive a weekly email with all my notifications": "Vastaanota viikottainen sähköposti, johon on kerätty ilmoitukset",
	"I don\'t want to receive any notifications": "En halua vastaanottaa ilmoituksia",
	"(we will send you a verification email)": "(saat vahvistussähköpostin)",
	"Current Password": "Voimassa oleva Salasana",
	"New Password": "Uusi Salasana",
	"Enter valid email": "Kirjoita voimassa oleva sähköposti",
	"Repeat New Password": "Toista Uusi Salasana",
	"Save Settings": "Tallenna asetukset",
	"Passwords should match": "Salasanan pitäisi olla ok",
	"Password length should be minimum 5 characters": "Salasanan pituus vähintään 5 merkkiä",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Huomio: Uusi sähköpostiosoitteesi pitää varmistaa seuraamalla linkkiä, jonka juuri lähetimme sinulle.",
	"I want to receive notifications by email": "Haluan saada ilmoituksia sähköpostiini",
	"Status": "Ehto",
	"Renewal": "Uudista",
	"Auto renewal Canceled": "Automaattinen tilauksen uusiminen peruttu",
	"Delete profile": "Poista profiili",
	"Your account has been deleted. You will be redirected.": "Tilisi on poistettu. Sinut ohjataan uudelleen.",
	"Successfully updated": "Onnistuneesti päivitetty",
	"Current account status": "Nykyinen tilin tyyppi",
	"Are you sure you want to cancel your subscription?": "Oletko varma, että haluat peruuttaa tilauksesi?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Tilauksesi on onnistuneesti peruttu, eikä sitä tulla automaattisesti uusimaan",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Teknisistä syistä, peruutus tai testitilauksen uusiminen pitää tehdä 8t jälkeen ostosta. Kaikkiin muihin maksutapoihin, peruutus pitää tehdä 8t oston jälkeen ja ennen 48t sen päättymistä.",
	"Profile deleted. Redirecting...": "Profiili poistettu. Uudelleenohjataan ...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Tällä tavalla poistettuja aktiivisia tilauksia ei kompensoida.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Oletko varma, että haluat poistaa tilisi? Tietosi poistetaan pysyvästi.",
	"Settings successfully updated": "Asetukset päivitetty onnistuneesti",
	"Disable Notification": "Poista ilmoitukset käytöstä",
	"The notifications have been successfully disabled.": "Ilmoitukset on onnistuneesti poistettu käytöstä.",
	"Canceled Auto-Renewal": "Automaattinen Tilauksen Uusiminen Peruttu",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Ainoastaan VIP jäsenet voivat ottaa yhteyttä useampaan kuin 5 käyttäjään päivässä.",
	"Upgrade your status": "Askel 1: Valitse jäsenyytesi",
	"Enjoy our exclusive benefits and make new contacts": "Nauti ainutlaatuisista eduista ja ole yhteydessä vähemmällä kuin 0.03E päivässä.",
	"Users Online": "Käyttäjää netissä",
	"Discreet Billing. {site_name} will not appear in the invoice": "Hienotunteinen Laskutus. {site_name} ei näy laskussa",
	"Month ::: Months": "Kuukausi ::: Kuukautta",
	"3 days trial": "3 päivän kokeilu",
	"Try 3 days": "Kokeile 3 Päivää",
	"MEMBERSHIP": "TILAUS",
	"Select": "Osta",
	"Send messages for 3 consecutive days": "Lähetä viestejä 3 päivää",
	"Only one trial subscription per user": "Ainoastaan yksi koetilaus per käyttäjä",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "Tilaus uusitaan automaattisesti perustuen yllä olevan valitun paketin hintaan",
	"for": " ",
	"For full access to all details of events, including how to cancel it,": "Täysi pääsy kaikkiin tilauksen yksityiskohtiin, mukaanlukien kuinka peruuttaa se,",
	"click here": "klikkaa tästä",
	"for terms and conditions,": "Käyttöehdot,",
	"All profiles are reviewed and moderated by our team.": "Tiimimme tarkistaa ja moderoi kaikki profiilit.",
	"CURRENT PLAN": "TÄMÄNHETKINEN TILAUS",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Perus (ei koebonusta): sinulla ei tällä hetkellä ole yhtään aktiivista pakettia ja olet PERUS käyttäjä. Kasvattaaksesi mahdollisuuksiasi ottaa yhteyttä muihin käyttäjiin tarvitset ELIITTI statuksen.",
	"MY BENEFITS": "MINUN ETUNI",
	"{discount} cheaper": "halvempi {discount}",
	"save": "POIS",
	"Create my profile": "Luo profiilini",
	"Read messages": "Lue viestit",
	"Add to Favorites": "Lisää suosikkeihin",
	"Make yourself visible to other users by adding them to favorites": "Tee itsesi muiden käyttäjien nähtäväksi lisäämällä heidät suosikkeihin",
	"Photo Album": "Kuva-Albumi",
	"No fake pictures allowed": "Ainoastaan aidot kuvat sallittu",
	"Album pictures": "Albumikuvat",
	"Show yourself and increase your possibilities": "Tule paikalle ja lisää mahdollisuuksiasi",
	"Detailed profile search": "Yksityiskohtainen profiilien haku",
	"Find exactly what you are looking for": "Löydä juuri se mitä etsit",
	"See other user's pics": "Näe muitten käyttäjien kuvia",
	"Choose your favorite": "Valitse se, josta pidät eniten",
	"Reply to and send messages": "Lähetä tai vastaa viesteihin",
	"Make direct contact with up to five users per day": "Ole yhteydessä enintään viiteen käyttäjään päivässä",
	"Be shown first in searches": "Ole ensimmäinen hakutuloksissa",
	"Your profile will be featured before the rest of the users": "Profiilisi on näkyvillä ennen muita käyttäjiä",
	"Make direct contact with unlimited users": "Ole yhteydessä rajoittamattomaan määrään käyttäjiä",
	"Choose payment method": "Valitse Maksutapa",
	"LESS THAN": "VAIN",
	"PER DAY": "PÄIVÄSSÄ",
	"DISCRETION": "ANONYYMI",
	"Our website name will not be disclosed": "Nimemme ei näy laskussa",
	"Our name will not be disclosed": "Nimemme ei näy laskussa",
	"100% Anonymous.": "100% Anonyymi.",
	"Choose your subscription": "Valitse tilauksesi",
	"Recommended": "Suositeltu",
	"Most Popular": "Suosituin",
	"Secure payments with": "Turvalliset maksut käyttämällä",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Viestiäsi ei ole lähetetty. Sinulla on oltava tilaus lähettääksesi viestejä.",
	"Your purchase has been successful": "Ostoksesi onnistui",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Kaikki tilaukset uusiutuvat automaattisesti palvelumukavuuden takaamiseksi. Jos haluat muuttaa asetuksia, voit tehdä sen",
	"here.": "täältä.",
	"after_here": "",

	//ad
	"Become a": "Osta",
	"PREMIUM MEMBER": "PREMIUM",
	"And contact": "Ja ole yhteydessä",
	"more people": "useampiin käyttäjiin",

	"upgrade": "Aloita nyt",
	"Boost": "Paranna",
	"boost": "Paranna",
	"your profile": "profiiliasi",
	"Start chatting and meet more people": "Ala juttelemaan ja tapaa ihmisiā",
	"And get": "Ja saa enemmän",
	"more views": "huomiota",

	"Now +3 extra Credits with each purchase": "Nyt 3 extra kolikkoa jokaisen ostoksen yhteydessä",
	"Best Seller": "Myydyin",
	"Select your Package": "Valitse pakettisi",
	"Less than {amount} per credit": "Vähemmän kuin {amount} per kolikko",
	"Less than": "Vähemmän kuin",
	"per credit": "per kolikko",
	"Free credits": "Ilmaista kolikkoa",
	"per purchase": "jokaisen ostoksen yhteydessä",
	"Premium Status doesn’t allow free messages.": "Premium-jäsenyys ei salli sinun lähettää ilmaisia viestejä.",
	"Chat now": "Juttele nyt",
	"Get Credits": "Hanki Kolikkoja",
	"Credit": "Kolikkoa",
	"Credits": "Kolikkoa",
	"My credits": "Kolikkoni",
	"{count} Credits left": "{count} Kolikkoa jäljellä",
	"Your message has not been sent, you need credits to be able to send messages.": "Viestiäsi ei ole lähetetty. Tarvitset kolikoita pystyäksesi lähettämään viestejä.",
	"Icebreaker": "Jäänmurtaja",
	"Icebreaker today": "Jäänmurtaja tänään",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Jäänmurtajan lähettäminen maksaa kaksi kolikkoa. Jäänmurtajaa saa käyttää vain keskustelun aloitukseen tai vastauksena toiseen jäänmurtajaan.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Jokaisen paketin oston yhteydessä käyttäjä ostaa tietyn määrän kolikoita. Käyttäjät voivat kuluttaa näitä kolikoita viestien lähettämiseen. Kolikoiden määrä per viesti on kymmenen, eli käyttäjällä tulee olla ainakin kymmenen kolikkoa tilillään lähettääkseen viestejä. Jäänmurtajan lähettäminen maksaa kaksi kolikkoa.",

	"Trial pack": "Kokeilupaketti",
	"Only one trial pack per user allowed": "Ainoastaan yksi kokeilupaketti per käyttäjä sallittu",
	"Used": "Käytetty",

	"Exclusive Promotion": "Erikoistarjous",
	"Account Deleted": "Tili poistettu",
	"Log out Successful": "Kirjautunut Ulos",

	"Do you like FREE LIVE SEX?": "Pidätkö ILMAISESTA LIVE SEKSISTÄ?",
	"Enter this code to enjoy a 10% discount:": "Saat 10% alennuksen käyttämällä koodia:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Koodi on voimassa seuraavat 6 tuntia ensimmäiseen nettiostokseen.",

	"This account has been deleted.": "Tämä tili on poistettu.",
	"Our algorithm has found a better option in line with your taste.": "Algoritmimme on löytänyt paremman vaihtoehdon, joka vastaa makuasi.",
	"You will be redirected in a few seconds.": "Sinut uudelleenohjataan muutaman sekunnin kuluttua.",

	"Has added you to favorites": "On lisännyt sinut suosikkeihinsa",
	"Is looking at your profile": "Katselee profiiliasi",
	"See profile": "Katso profiili",
	"See message": "Katso viesti",

	"I think I am in love": "Olen varmaan rakastunut",
	"Hey! I'm winking at you": "Tämä silmänisku on sinulle",
	"Tell me more about you. Fancy a chat?": "Kerro itsestäsi lisää. Haluutko jutella?",
	"Some flowers for you!": "Kukkia sinulle!",
	"Sending you a kiss": "Haluatko suukon?",
	"Surprise, it\'s me!": "Ylläri, se on minä!",
	"Hi, how are you?": "Mitä kuuluu?",
	"Let's get naughty": "Pidetäänkö tuhmaa kivaa",

	"Get 5 times the regular response rate. Premium members benefits:": "Premiun jäsenyyden edut: 5 kertaa enemmän trafiikkia/ säännöllinen vastausnopeus:",
	"Appear high up on searches": "Esiinny hakujen kärjessä",
	"Distinctive badge that increases your visibility": "Selvästi erottuva merkki, joka lisää näkyvyyttäsi",
	"5 Free Icebreakers a day": "5 ilmaista Jäänmurtajaa päivässä",
	"In addition to this, a voucher for a 15% Webcam discount": "Ja lisäksi, 15% alennuskuponki Webcamiin",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Jäänmurtajaa saa käyttää vain keskustelun aloituksiin tai vastauksena toiseen jäänmurtajaan.",

	"Succesful purchase": "Ostoksesi on onnistunut",
	"Your purchase has been successful.": "Ostoksesi on onnistunut.",
	"It can take a few minutes to update.": "Sen päivittyminen tilillesi saattaa kestää muutaman minuutin.",
	"You will be redirected.": "Uudelleenohjataan.",

	"Changes can take some minutes to update.": "Muutoksissa saattaa kestää muutama minuutti.",

	"Yearly Package": "Vuosipaketti",
	"Package": "Paketti",
	"Buy Now": "Osta Nyt",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Eliitti Jäsenyys kanssa pystyt ottamaan yhteyttä maksimissaan 5 jäseneen päivässä ja lähettämään heille rajattoman määrän viestejä.",

	"Exclusive offer": "Erikoistarjous",
	"Welcome": "Tervetuloa",
	"Mary from {domain_name} support": "Mari {domain_name} sivuston Tukitiimistä",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Tervetuloa {domain_name} sivustolle. Toivomme, että täältä löydät etsimäsi.",
	"Here are some Pro Tips to be more successful and meet people:": "Tässä joitakin Huippuvinkkejä, jotka auttavat sinua menestymään sivustolla ja tapaamaan ihmisiä:",
	"Upload a profile picture to get more connections.": "Lataa profiilikuva saadaksesi enemmän kontakteja.",
	"Send an icebreaker to get a conversation easily started.": "Lähetä Jäänmurtaja aloittaaksesi keskustelu helpolla tavalla.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Jos pidät jostakin, älä unohda lisätä heitä suosikkeihisi, jotta he tietävät myös.",
	"And of course, if you like somebody send a message and ask for a date!": "Ja tietenkin, jos pidät jostakin, lähetä viesti ja pyydä häntä treffeille!",
	"If you need any extra help, please contact our ": "Jos tarvitset apua, ota yhteyttä ",
	"support": "asiakastukeen",
	". We will be happy to help you.": ". Autamme mielellämme.",
	"Try your luck": "Kokeile onneasi",
	"64% of our users have a date in the first 2 weeks.": "64% käyttäjistämme menee treffeille ensimmäisen 2 viikon aikana.",
	"Good Luck!.": "Onnea!",
	"*This message will be deleted upon closing.": "*Tämä viesti poistuu, kun suljet sen.",

	"Private picture": "Yksityiset kuvat",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Yksityiset kuvat näkyvät sumennettuina. Ainoastaan jäsenyydestään jotain jo maksaneet käyttäjät voivat nähdä ne.",
	"Name change": "Nimenvaihto",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Jos haluat vaihtaa nimesi tai nimimerkkisi, ota yhteyttä asiakastukeemme, ja he vaihtavat sen puolestasi 24 tunnin sisällä.",
	"See all images": "Näe kaikki kuvat",
	"Unlock all private photos forever for just 20 credits": "Näe kaikki yksityiset kuvat pysyvästi vain 20 kolikolla",
	"Unlock forever all private pictures": "Näe kaikkien yksityiset kuvat pysyvästi",

	"This email address is not registered, please sign up to log in.": "Tämä sähköpostiosoite ei ole tietokannassamme, ole hyvä ja rekisteröidy kirjautuaksesi.",
	"Congratulations, your account is now active.": "Onneksi olkoon, tilisi on nyt aktivoitu.",
	"Complete one last step and have fun!": "Suorita loppuun viimeinen vaihe ja pidä hauskaa!",
	"OR": "TAI",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Olemme lähettäneet sinulle sähköpostin, jossa on linkki tilisi aktivoimiseksi ja se on voimassa seuraavat 12 tuntia. Se on lähetetty osoitteeseen:",
	"If you haven\'t received it in your inbox or ": "Jos et ole vastaanottanut sitä sähköpostilaatikkoosi tai ",
	"or you want to modify the email address, click on the button below.": "tai haluat muokata sähköpostiosoitettasi, klikkaa alla olevaa linkkiä.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Olemme lähettäneet sinulle sähköpostilla tilisi varmennuksen, se on voimassa seuraavat 12 tuntia:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Jos et löydä sitä sähköpostistasi tai roskapostistasi, voit editoida tietoja tai uudelleenlähettää",
	"Resend or change email": "Uudelleenlähetä tai vaihda sähköpostiosoite",
	"Please use the same email you registered.": "Please use the same email you registered.",
	"Connect with Google": "Kirjaudu Google-tunnuksilla",
	"Connect with Yahoo": "Rekisteröidy Yahoo tililläsi",
	"Connect with Microsoft": "Kirjaudu Hotmail-tunnuksilla",
	"Or turn back": "Palaa",
	"Complete your registration": "Suorita rekisteröinti loppuun",

	"It will not be shared": "Sitä ei julkisteta",
	"It will never be shared. 100% Privacy": "Kirjoita sähköpostiosoittees",

	"Unlock all private pictures forever.": "Näe kaikkien yksityiset kuvat pysyvästi.",
	"Unlock": "Avaa",

	"You will be redirected in a second": "Odota, kun sinut siirretään seuraavalle sivulle",

	"Card payment": "Korttimaksu",
	"Bank transfer": "Pankkisiirto",
	"Prepaid card": " Prepaid -kortti",

	"Error": "Virhe",
	"page not found": "Sivua ei löydetty",
	"Back to homepage": "Takaisin kotisivulle",

	"per month": "kuukaudessa",
	"week": "viikko",
	"{count} week": "{count} viikko",

	"Your profile has been blocked": "Profiilisi on lukittu",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Parhaimman mahdollisen käyttäjäkokemuksen mahdollistamiseksi lukitsemme kaikki profiilit, jotka eivät ole lähettäneet viestejä 21 päivän sisällä kirjautumisesta.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Ole hyvä ja osta kolikkopaketti avataksesi profiilisi ja palauttaaksesi kaikki sen toiminnot.",
	"Restore access to:": "Palauta käyttöoikeus:",
	"All messages": "Kaikki viestit",
	"Users search": "Käyttäjien haku",
	"Your contacts": "Kontaktisi",
	"All favourites": "Kaikki suosikkisi",
	"All pictures": "Kaikki kuvat",
	"Send Icebreakers": "Lähetä jäänmurtaja",
	"Unblock Profile": "Vapauta profiili",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Tämä verkkosivusto käyttää omia ja kolmansien osapuolien evästeitä ja tunnisteita saavutettavuuden parantamiseksi ja liikenteen analysoimiseksi. Jos jatkat selaamista, hyväksyt niiden käytön. Lisätietoja saat evästeiden käytöstä täältä.",
	"Cookies Policy": "Evästekäytäntö",

	"DATE GUARANTEE": "TREFFIVAKUUTUS",
	"Take advantage of our Date guarantee": "Hyödynnä treffivakuutustamme",
	"No date? Money Back!": "Ei treffejä? Rahat takaisin!",
	"No risk": "Ei riskiä",
	"Guarantee of success": "Takuuvarma menestys",
	"Terms and Conditions": "Käyttöehdot",

	"Boost your visibility": "Paranna näkyvyyttäsi",
	"SAVE": "SÄÄSTÄ",
	"Add 3 months premium": "Lisää 3 kuukauden premium",
	"Add {months} months premium": "Lisää {months} kuukauden premium",

	"We guarantee you a real date.": "Vakuutamme, että pääset aidoille treffeille, ja lupaamme.",
	"YES, I WANT A DATE": "KYLLÄ, HALUAN TREFFIT",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Treffitakuun käyttöehdot. Täyttääksesi kaikki palautusehdot, sinun tulee täydentää profiilisi, kuvaus mukaan lukien, ainakin 6 kuukauden ajaksi. Profiilin tulee sisältää ainakin yksi hyväksytty kuva tämän aikajakson keston ajan. Lisäksi sinun tulee joka viikko ottaa yhteyttä ainakin seitsemään lähelläsi asuvaan jäseneen (100 kilometrin säteellä), jotka ovat ikäluokkaasi (-5/ +5). Jos täytät kaikki ylläolevat ehdot, mutta et silti pääse treffeille, me palautamme kaikki suorittamasi maksut, jotka olet tehnyt tänä aikana. Takuu on voimassa ainoastaan 12 kuukautta rekisteröinnistä. Onnea!",

	"You have {count} days premium left": "Premium tilauksesi on {count} päivää voimassa.",
	"Check our special offer before deleting your account!": "Ennen kuin poistat tilisi, tarkista erikoistarjouksemme!",
	"Delete my account": "Poista tili",
	"Free": "Ilmainen",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Premium, Eliitti ja VIP paketit uusiutuvat automaattisesti, ja tämä tarkoittaa, että ne veloitetaan automaattisesti tilauskauden umpeutuessa.",
	"and can confirm that I am 18 years or older.": "ja vahvistan olevani yli 18 vuoden ikäinen.",

	"COMPLETE YOUR PURCHASE": "SUORITA LOPPUUN OSTOKSESI",
	"Payment details": "Maksutiedot",
	"Total": "Loppusumma",
	"3-digit number on the back of your credit card": "3-numeroinen turvakoodi luottokorttisi takapuolella",
	"MM / YY": "KK / VV",
	"MM": "KK",
	"YY": "VV",
	"Name on card": "Nimi kortilla",
	"Charges will appear discreetly as tpmpsup.com": "Veloitukset näkyvät huomaamattomasti virtualcharges.com nimellä",
	"Buy Now": "Osta Nyt",
	"Premium package 3 Months": "Premium paketti 3 kuukautta",

	"This purchase was declined. Please check your details and try again.": "Tämä ostos on hylätty. Ole hyvä ja tarkista tietosi ja yritä uudelleen.",
	"Your purchase was succesful.": "Ostoksesi onnistui.",

	"SPECIAL OFFER": "ERIKOISTARJOUS",
	"Unlock your account with our special offer": "Vapauta tilisi erikoistarjouksellamme",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Hyödynnä erikoistarjoustamme ja vapauta profiilisi ja kaikki sen toiminnot.",

	"card_number_require": "Kortin numero vaaditaan",
	"card_number_invalid": "Kortin numero väärä",
	"card_name_require": "Kortinhaltijan nimi vaaditaan",
	"card_name_invalid": "Kortinhaltijan nimi väärä",
	"card_cvc_require": "CVC vaaditaan",
	"card_cvc_invalid": "CVC väärä",
	"card_expiry_require": "Kortin voimassaolo vaaditaan",
	"card_expiry_invalid": "Kortin voimassaolo väärä",

	"Private photo": "Yksityinen kuva",
	"Search for someone now": "Etsi joku nyt",
	"Edit": "Muokkaa",

	"Locked Album": "Albumi blokattu",
	"Get credits to unlock all albums": "Osta kolikoita avataksesi kaikki albumit",
	"picture ::: pictures": "kuva ::: kuvat",
	"Open": "Avaa",

	"Receive notifications": "Vastaanota ilmoituksia",
	"I want to be notified when I receive a message": "Haluan ilmoituksen kun saan viestin",
	"No": "Ei",
	"Yes": "Kyllä",

	"logout_confirm_title": "Kirjaudu ulos",
	"Are you sure you want to log out?": "Haluatko varmasti kirjautua ulos?",

	"Your payment has been cancelled": "Maksusi on peruttu",
	"Something went wrong, please try again in 10 minutes": "Jotain meni pieleen, yritä uudestaan 10 minuutin kuluttua",

	"Please accept the terms and conditions to continue": "Ole hyvä ja hyväksy käyttöehdot jatkaaksesi",
	"By checking this box and clicking continue I accept the": "Valitsemalla tämän ruudun ja klikkaamalla jatka hyväksyn",
	"rg_terms": "Käyttöehdot",
	"rg_privacy": "Yksityisyydensuojan",
	"rg_cookies": "Evästeet ja",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "olen tietoinen sivustolla olevista kuvitteellisista profiileista ja vahvistan olevani täysi-ikäinen.",

	"Cookie": "Eväste",
	"Responsible Party": "Vastuullinen osapuoli",
	"Category": "Kategoria",
	"Cookie Name": "Nimi",
	"Purpose": "Tarkoitus",
	"Lifespan": "Kestoaika",
	"Type of Cookie": "Evästetyyppi",
	"Strictly necessary": "Pakollinen",
	"Functional": "Toiminnallinen",
	"Performance": "Suorituskykyevästeet",
	"Advertising": "Advertising",
	"cookie-policy": "evastekaytanto",
	"cookie policy": "evästeiden käytäntö",

	"We Care About Your Privacy": "Me välitämme yksityisyydensuojastasi",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Tämä sivusto käyttää omia, teknisiä ja kolmansien osapuolten evästeitä parantamaan navigoinnin suorituskykyä analysoimalla kävijöiden käyttäytymistä ja se auttaa meitä ja markkinointikumppaneitamme mittaamaan kampanjan tuloksia ja personalisoimaan markkinointia. Klikkaamalla \"Hyväksy kaikki\" hyväksyt kaikki nämä evästeet.",
	"Accept All": "Hyväksy kaikki",
	"Cookies Settings": "Evästeiden asetukset",
	"About your privacy": "Yksityisyydensuojastasi",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Käytämme evästeitä liikenteen tasapainottamiseen, istuntojen tunnistamiseen, analytiikkaan ja markkinoinnin määritykseen. Voit hylätä tai hyväksyä osan tai kaikki niistä tässä paneelissa.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Voit muuttaa asetuksiasi milloin tahansa :cookies_policy page sivulla",
	"Accept all": "Hyväksy kaikki",
	"Manage Consent Preferences": "Hallinnoi suostumusasetuksia",
	"Technical (Mandatory)": "Tekninen (välttämätön)",
	"Performance (Monitor website performance)": "Toiminnallinen (verkkosivuston toiminnan monitorointi)",
	"Functional (User experience improvement)": "Suoritus (käyttökokemuksen parantaminen)",
	"Advertising (Marketing measurement)": "Mainonta (markkinointitoimenpiteet)",
    "Advertising (Personalization)": "Mainonta (personointi)",
	"Save Selection": "Tallenna valitut",
	"Reject All": "Hylkää kaikki",

	"We and our partners process data to provide:": "Me and kumppanimme prosessoimme dataa tarjotaksemme:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Käytä tarkkoja sijaintitietoja. Skannaa laitteen ominaisuudet aktiivisesti tunnistamista varten. Tallenna ja / tai käytä tietoja laitteessa. Räätälöidyt mainokset ja sisältö, mainosten ja sisällön mittaus, yleisötiedot ja tuotekehitys.",
	"List of partners": "Luettelo kumppaneista",
	"Cookie Policy": "Evästekäytäntö",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Näitä evästeitä käytetään mainoksiin liittyvien toimien seuraamiseen. Nämä evästeet esimerkiksi muistavat, että olet käynyt Ilman ehtoja sivustolla, ja niitä voidaan käyttää näyttämään sinulle henkilökohtainen mainos toisella verkkosivustolla. Tarkista seuraavat linkit alustoille, joilla mainostamme itseämme, Google, Microsoft ja Twitter. Lisätietoja.",
	"Delete cookies": "Cookies",
	"Save selection": "Tallenna valitut",
	"Reject all": "Hylkää kaikki",

	"{name} hasn't replied to you yet. Send another message!": "{name} ei ole vastannut sinulle vielä. Lähetä toinen viesti!",
	"Validate my Ice-breaker": "Vahvista jäänmurtajani",

	"Distance": "Välimatka",
	"now": "nyt",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "1.6.2023 alkaen emme hyväksy PaySafeCard-maksuja.",

	"Dear User,": "Hyvä käyttäjä,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Kiitos mielenkiinnostasi liittyä sivullemme, mutta jotta sukupuolten välinen tasapaino pysyisi lähellä 50/50 miesten ja naisten välillä, miehille rekisteröityminen ei ole tällä hetkellä sallittua.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Jos haluat silti treffeille, napsauta yllä olevaa painiketta ja kokeile yhtä parhaista verkkosivustoista.",
	"Try Now": "Kokeile nyt",

	"Your account has been deleted.": "Tilisi on poistettu.",
	"According to your choices, our algorithms have found a better option for you.": "Valintojesi mukaan algoritmimme ovat löytäneet sinulle paremman vaihtoehdon.",
	"See More": "Katso lisää",

	"10 credits for you!": "10 krediittiä sinulle!",
	"Get 3 free Icebreakers": "Saa 3 ilmaista jäänmurtajaa",
	"Free Icebreakers": "Ilmaista jäänmurtajaa",
	"Congratulations, you have received 3 Icebreakers!": "Onnittelut, olet saanut 3 jäänmurtajaa!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay toimii vain Safari-selaimen kanssa. Avaa Safari-selain ja kirjaudu tilillesi jatkaaksesi tämän maksun suorittamista.",

	"No nudity": "Ei alastomuutta",
	"Nudity allowed": "Alastomuus sallittu",

	"Edit profile": "Muokkaa profiilia",
	"Online": "Verkossa",
	"Step": "Jatka",
	"Completed": "Suoritettu",
	"Help": "Apua",
	"or Drag and drop": "tai vedä ja pudota",

	"You will be able to send messages in": "Voit lähettää viestejä kun aikaa on kulunut",
	"Send": "Lähettää",
	"Success": "Onnistunut",
	"Start a conversation with {name}": "Aloita keskustelu hänen kanssaan: {name}",
	"Failed": "Epäonnistui",
	"Congratulations, your account has been successfully created.": "Onnittelut, tilisi on luotu onnistuneesti.",
	"Contact our support": "Ota yhteyttä tukeemme",

	"About": "Minusta",
	"Scale": "Säädä",
	"Rotate": "Kierrä",

	"No favorites added": "Syötä sähköposti",

	"Enter Email": "Syötä sähköposti",

    "We have sent you an email to:": "Olemme lähettäneet sinulle sähköpostia osoitteeseen:",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Vahvista klikkaamalla \"Poista tili\" -painiketta kyseisessä sähköpostissa, niin tilisi poistetaan pysyvästi järjestelmästämme.",
    "DELETE ACCOUNT": "Poista tili",

	"Didn\'t find what you were looking for?": "Etkö löytänyt etsimääsi?",
	"Please send us an email and we will get back to you.": "Lähetä meille sähköpostia, niin otamme sinuun yhteyttä.",
	"Or contact us using the form": "Tai ota meihin yhteyttä lomakkeella.",

	"BOOST YOUR ACCOUNT": "TEHOSTA TILIÄSI",
	"We will send you an email to boost your account:": "Lähetämme sinulle sähköpostin, jolla voit tehostaa tiliäsi:",
	"Get Free exclusive benefits": "Hanki Ilmaisia ainutlaatuisia etuja",
	"Premium Status 24h": "Premium-tila 24h",
	"5 Free Credits": "5 Ilmaista Krediittiä",
	"3 Free Icebreakers": "3 Ilmaista Jäänmurtajaa",
	"IMPROVE ACCOUNT": "TEHOSTA TILIÄ",

	"COMPLETE REGISTRATION": "VAHVISTA REKISTERÖINTI",
	"Confirm your email and complete registration": "Vahvista sähköpostiosoitteesi ja suorita rekisteröinti loppuun",
	"Benefits": "Edut",
	"More Visibility": "Enemmän näkyvyyttä",
	"Send Messages": "Lähetä viestejä",

    "Your registration is completed": "Rekisteröintisi on valmis",

}
