export default {
	"Already a member?": "Sei già Membro?",
	"Login": "ENTRA",
	"Log out": "Uscire",
	"Your current status": "Il tuo stato attuale",
	"BASIC": "Base",
	"Basic": "Base",
	"Bronze": "Base",
	"Elite": "Elite",
	"VIP": "VIP",
	// "Join Us": "Entra ahora",
	// "Find people near you": "Encuentra gente cerca de ti",
	"Join now": "Entrare gratis",
	"I am a": "Sono",
	"Email": "Email",
	"It will not be published": "Inserisci I'email",
	"E-mail address": "Inserisci I'email",
	"SEARCH NOW": "JOIN FREE",
	"I confirm that I am over 18 years old": "Confermo di essere maggiorenne",
	// "Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "No lo pienses más y entra al fenómeno social del momento. Cada día miles de personas como tú se unen a nosotros.",
	"Information": "Informazioni",
	"All rights reserved.": "Tutti I diritti sono riservati.",
	"SUBSCRIPTION": "STATO",
	"Other": "Altre questioni",
	"All fields are required": "Tutti i campi sono obbligatori",
	"The fields marked in Red are required. Please fill them to continue.": "I campi contrassegnati in rosso sono obbligatori. Ti preghiamo di riempirli per continuare.",
	"If you did not find a suitable answer please contact us": "Se non hai trovato l'argomento appropriato, ti preghiamo di scriverci",
	"Will help us to identify you": "Ci aiuterà ad identificarti",
	"Invalid Username": "Nickname non valido",
	"Subject": "Motivo",
	"Tell us how can we help you": "Dicci come ti possiamo aiutare",
	"Please enter subject": "Per favore introduci motivo",
	"Please enter at least 10 characters": "Per favore introduci minimo 10 caratteri",
	"Connected to your account": "Conessa al tuo account",
	"Repeat Email": "Ripeti Email",
	"Emails did not match": "L’indirizzo email non coincide",
	"Message": "Messaggi",
	"No Recent Activity": "Nessuna attività recente",

	//route labels
	"Home": "Inizio",
	"Contact": "Contatto",
	"Support": "Supporto",
	"Affiliates": "Affiliati",
	"Privacy": "Privacy",
	"Terms": "Termini",

	//routes uri
	"/login": "/iniziare-sessione",
	"/contact": "/contact",
	"/support": "/supporto",
	"/affiliates": "/programma-di-affiliazione",
	"/privacy": "/Privacy",
	"/terms-and-conditions": "/termini-e-condizioni-di-uso-generale",
	"/cookie-policy": "/politica-dei-cookie",
	"/subscription/exit": "/abbonamento/uscita",
	"/purchase-confirmation": "/conferma-acquisto",
	"/page/:page_name": "/page/:page_name",

	"/dashboard": "/inizio",
	"/mobile-dashboard": "/mobile-cruscotto",
	"/activate-profile/:token": "/attivare-profilo/:token",
	"/edit-profile": "/mio-profilo",
	"/profile/:name": "/profilo/:name",
	"/my-picture": "/foto-mie",
	"/messages/inbox": "/messaggi/inbox",
	"/messages/trash": "/messaggi/cestino",
	"/messages/send/:name": "/messaggi/inviare/:name",
	"/visitor": "/chi-mi-ha-visto",
	"/favorite/my": "/miei-preferiti/mio",
	"/favorite/others": "/miei-preferiti/altri",
	"/search": "/cercare-profili",
	"/search-result": "/resultado-busqueda",
	"/settings": "/modifiche",
	"/validate-new-email-address/:token": "/controllare-nuevo-indirizzo-email/:token",
	"/subscription": "/abbonamento",
	"/credits": "/creditti",
	"/elite": "/elite",
	"/faq": "/domande-e-risposte",
	"/delete-account": "/account-eliminato",

	"/exclusive-promotion": "/promozione-esclusiva",
	"/account-deleted": "/account-cancellato",
	"/logged-out": "/log-out-eseguito",
    "/complete-register/:token" : '/completa-registrazione/:token',

	//login
	"Login": "Login",
	"Enter username or Email": "Introduci nome utente o email",
	"Enter Password": "Introduci Password",
	"Username": "Nickname",
	"Nickname": "Nickname",
	"Password": "Password",
	"I forgot my password": "Ho dimenticato la password",
	"Password Reminder": "Ricordare Password",
	"Enter you email": "Introduci il tuo indirizzo di posta elettronica",
	"Your email address": "Tuo indirizzo email",
	"Don't have an account?": "Non hai ancora un account?",
	"REGISTER": "ENTRA",
	"Password sent to your email": "Password inviata al tuo indirizzo email",
	"This account has not been validated yet": "Questo account non è stato ancora attivato",
	"LOGIN USING YOUR CREDENTIALS": "INTRODUCI TUOI DATI",
	"FORGOT YOUR PASSWORD?": "DIMENTICATO PASSWORD?",
	"Login failed": "Errore di connessione",
	"Username or password is incorrect": "Nome utente o password incorretti",

	"We're sorry!": "Ci spiace",
	//registration
	"You're almost done!": "Hai quasi terminato!",
	"Please fill your additional account information": "Por favore riempi le informazioni del tuo account",
	"Just one step left!": "Solo un altro passo!",
	"Let us learn more about you. Fill in your personal data": "Facci sapere di più su di te. Compila le tue informazioni personali",
	"Activate your account now": "Attiva il tuo account ora!",
	"Activate your account now.": "Attiva il tuo account ora!",
	"Please confirm your e-mail address": "Conferma il tuo indirizzo email",
	"Please fill in the following form to join {site_name} for free": "Per favore riempi il seguente formulario per registrarti gratuitamente a {site_name}",
	"CONTINUE": "CONTINUARE",

	"By clicking on the continue button I agree with the": "Cliccando su \“Continuare\” acconsento ai",
	"terms": "termini di utilizzo",
	"and cookies": "e uso dei cookie",

	"This site is protected by reCaptcha and the Google": "Questo sito è protetto da reCaptcha e si applicano le",
	"Privacy Policy": "Norme sulla privacy",
	"and": "e i",
	"Terms of Service": "Termini di servizio",
	"apply.": "di Google",

	"Personal description": "Descrizione personale",
	"optional": "opzionale",
	"FINALIZE": "COMPLETARE",
	"We have just sent you a confirmation e-mail at": "Ti abbiamo inviato un'email di conferma a",
	"To start using our website, please click on the activation link in this email.": "Per iniziare a utilizzare la nostra web, fai clic sul link di attivazione in questa email.",
	"Click here to verify your email": "Clicca qui per verificare la tua mail",
	"CLICK TO ACTIVATE": "CLICCA PER ATTIVARLA",
	"CLICK TO ACTIVATE - desktop": "CLICCA PER ATTIVARLA",
	"VERIFY MY EMAIL": "VERIFICA LA MAIL",
	"Not received any email yet?": "Non hai ancora ricevuto posta?",
	"Please check your": "Verifica la tua cartella",
	"spam": "spam",
	"box and wait at least 10 minutes or re-send activation": ", e aspetta almeno 10 minuti o rinvia l'attivazione.",
	"Enter Nickname": "Introduci Nickname",
	"Enter Your Name": "Introduci il Tuo Nome",
	"Password Required": "Password richiesta",
	"Enter valid Email": "Inserisci una email valida",
	"Please enter at least 5 characters": "Por favore introduci 5 caratteri",
	"Enter strong password": "Introduci password forte",
	"Email not available": "Email non disponible",
	"Nickname not available": "Nickname non disponible",
	"Maximum 4 number allowed": "Massimo permesso 4 cifre",
	"Name should not contain any space, number or special character": "Il nome non dovrebbe contenere spazi, numeri o caratteri speciali",
	"Nickname should not contain any space or special characters": "Il nickname non dovrebbe contenere spazi o caratteri speciali",
	"Your activation mail has been sent": "La tua email di attivazione è stata inviata",
	"Complete your profile in a minute and start contacting people": "Completa il tuo profilo in un minuto e contatta gente",
	"Complete your profile": "Completa il profilo",
	"Create Profile": "Creare Profilo",
	"Your description": "La tua descrizione",
	"Confirm your Email": "Conferma la tua email",
	"We have sent you an email.": "Ti abbiamo inviato una mail.",
	"Check your email to complete your registration": "Controlla la tua casella di posta in arrivo per completare la registrazione",
	"and be able to speak with other users.": "e per poter parlare con altri utenti.",
	"If you can not find it look it up in the spam or": "Se non la trovi, controlla nella cartella",
	"If you have not received it select one of the following options": "Se non l'hai ricevuta, seleziona una delle seguenti opzioni",
	"junk folder": "posta indesiderata",
	"Change Email": "Cambia email",
	"Resend Email": "Inoltra email",
	"Resend": "Reinvia",
	"New Email": "Nuova email",
	"Your activation key is invalid": "La chiave di attivazione non è valida",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Per favore aggiunga una @ all'indirizzo di posta elettronica. '{email}' non ha la '@'.",

	//public pages
	"privacy": "privacy",
	"terms-and-conditions": "termini-e-condizioni-di-uso-generale",
	"about-us": "chi-siamo",

	//forms
	"Name": "Nome",
	"Marital Status": "Stato",
	"Date of Birth": "Data di Nascita",
	"Country": "Paese",
	"Location": "Regione",
	"Province": "Regione",
	"City": "Città",
	"Save Changes": "Salvare Modifiche",
	"Select from list": "Scegli dalla lista",
	"Day": "Giorno",
	"Month": "Mese",
	"month": "Mese",
	"Year": "Anno",
	"Prefer not to say": "Preferisco non dirlo",
	"Only profiles with photo": "Solo profili con foto",
	"This field is required": "Questo campo è richiesto",
	"Please update your profile": "Aggiorna il tuo profilo",
	"Profile successfully updated": "Profilo aggiornato correttamente",
	"The fields marked in red are required. Please fill them to continue.": "I campi contrassegnati in rosso sono obbligatori. Ti preghiamo di riempirli per continuare.",
	"Characters left": "Caratteri rimasti",
	"Image attached": "Immagine allegata",
	"The message field is required.": "Il campo messaggio è richiesto.",
	"WITH PICTURE": "CON FOTO",
	"all age groups": "SELEZIONA ETA’",
	"EDIT DESCRIPTION": "MODIFICA DESCRIZIONE",
	"DESCRIPTION": "DESCRIZIONE",
	"FEATURING": "PERSONALITA´",
	"Please Select": "Por favore Seleziona",
	"Emails do not match": "L’indirizzo email non coincide",
	"Your request was succesfully sent!": "La Tua richiesta è stata inviata correttamente!",
	"Or send us a message directly": "Oppure inviaci un e-mail direttamente",

	//user pages
	"My Profile": "Mio Profilo",
	"Add Photos": "Aggiungere Foto",
	"Messages": "Messaggi",
	"Favorites": "Favoriti",
	"Search": "Ricerca",
	"Settings": "Rettifiche",
	"Visits": "Visite",
	"Likes": "Favorito di altri",
	"Membership": "Stato",
	"Vip Members": "Utente Top",
	"Profile_mobile_menu": "Profilo",

	"Account activation successful": "Attivazione dell'account con successo",
	"My chats": "Le mie chat",

	//user home
	"Quick Search": "Ricerca Rapida",
	"More Options": "Più Opzioni",
	"I am a": "Sono",
	"Looking for": "Cerco",
	"Age": "Età",
	"Search for": "Cercare",
	"All age groups": "Tutti i gruppi d’età",
	"Popular Members": "Membri Popolari",
	"New Members": "Nuovi Membri",
	"Find a Match": "Ricerca",
	"Recent Activity": "Attività Recente",
	"Add Photo": "Aggiungere foto",
	"Back": "Indietro",
	"Become Elite": "Migliorare",
	"Upgrade": "MIGLIORARE",

	"likes": "SONO FAVORITO",
	"EDIT MY PROFILE": "MODIFICA PROFILO",
	"CONFIGURATION": "CONFIGURARE",
	"ONLINE SUPPORT": "SUPPORTO ONLINE",

	//user message
	"Trash": "Cestino",
	"Delete Selected": "Cancellare selezionato",
	"No Messages Available": "Nessun Messaggio disponibile",
	"No Messages selected": "Non hai messaggi selezionati",
	"Back to inbox": "Ritornare alla pagina di inizio",
	"Back to messages": "Tornare al menssaggio",
	"Select all": "Selezionare tutto",
	"Deselect all": "Deselezionare tutto",
	"Next Page": "Pagina Successiva",
	"Prev Page": "Pagina Precedente",
	"Type your message here": "Scrivi il tuo messaggio",
	"Send message": "Inviare Messaggio",
	"Send Icebreaker": "Inviare Rompighiaccio",
	"VIEW PROFILE": "VEDI PROFILO",
	"DELETE": "Cancellare",
	"Chat with": "Chatta con",
	"Chat": "Chatta",

	//user favorites
	"My Favorites": "Miei favoriti",
	"No likes": "Non hai favoriti",
	"Favorite added successfully": "Favorito aggiunto con esito",
	"Favorite deleted successfully": "Favorito cancellato con esito",
	"Visit Profile": "Visita Profilo",

	//user visits
	"My Profile Visitors": "Visite al Mio Profilo",
	"No Visitors": "Senza Visite",

	//user photos
	"My pictures": "Foto mie",
	"See my public profile": "Visualizza il mio profilo pubblico",
	"Profile photo": "Foto del Profilo",
	"Profile Picture": "Foto del Profilo",
	"2MB or lower.": "2MB o meno.",
	"2MB or lower": "2MB o meno",
	"Nudes not allowed in this category.": "Nudi NON ammessi in questa categoria.",
	"PHOTO ALBUM": "ALBUM DI FOTO",
	"Allowed images 2 megabytes or less": "Immagini consentite di 2 megabyte o meno",
	"Add picture": "Aggiungere foto",
	"Main reasons for rejection": "PRINCIPALI MOTIVI DI RIFIUTO",
	"Are you sure?": "Sei sicuro?",
	"Are you sure you want to delete this image?": "Sei sicuro di voler eliminare questa immagine?",
	"Close": "Chiudere",
	"Confirm": "Confermare",
	"Attaching image": "Allegando immaginne",
	"The message must be at least 2 characters.": "Questo messaggio deve contenere almeno 2 caratteri.",
	"Deleting...": "Cancellando...",
	"Pictures of underage people": "Foto di minori",
	"Personal data is visible": "con dati personali",
	"Fake or stolen pictures": "foto false o rubate",
	"Different gender to profile": "inserimento errato del genere",
	"Group pictures": "Foto di gruppo",
	"Weapons, drugs, violence": "Armi, droga, violenza",
	"No people visible": "Non c'è una persona rappresentata",
	"Info": "Informazioni",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Stiamo esaminando le tue immagini. Saranno mostrate agli altri utenti dopo essere state esaminate.",

	//user profiles
	"Profile": "Profilo di",
	"yrs": "Anni",
	"years": "anni",
	"yr from": "anni di",
	"Physique": "Costituzione",
	"Photos": "Foto",
	"No Photos": "Senza Foto",
	"About Me": "Su di me",
	"Characteristics": "Caratteristiche",
	"Prefers": "Preferenze",
	"Relationship": "Relazione",
	"Shape": "Forma",

	//user edit profile
	"DESCRIBE YOURSELF": "DESCRIVITI",
	"GENERAL": "GENERALE",
	"(Will be verified)": "(Verrà controllato manualmente)",
	"Sex": "Sesso",
	"Seeking": "Cercando",
	"Finish your profile": "Compila il tuo profilo",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Benvenuto! Visto che il tuo profilo è nuovo, se lo completi otterrai la massima visibilità.",

	//search profile
	"Advanced Search": "Ricerca Avanzata",
	"OPEN TO": "APERTO A",
	"PREFERENCES": "PREFERENZE",
	"CHARACTER": "CARATTERE",
	"Search Result": "Risultato della ricerca",
	"No Member found": "Membro non trovato",
	"Change Search": "Cambiare la ricerca",
	"Hair Color": "Capelli",
	"Eye Color": "Occhi",
	"Body Type": "Costituzione",
	"Height": "Statura",
	"Weight": "Peso",
	"Smoker": "Fumatore",
	"Ethnicity": "Gruppo etnico",
	"From": "da",
	"To": "A",

	//settings
	"Change E-mail address": "Cambiare Indirizzo di Posta Elettronica",
	"Your email address has been successfully updated": "L'indirizzo email è stato aggiornato correttamente",
	"Password": "Password",
	"Account Status": "Stato del profilo",
	"Cancel": "Cancellare",
	"Delete Account": "Cancellare Account",
	"Notifications": "Notifiche",
	"Receive all notifications individually": "Ricevere tutte le notifiche individualmente",
	"Receive one daily email with all my notifications": "Riceverle raggruppate in una mail giornaliera",
	"Receive a weekly email with all my notifications": "Riceverle raggruppate in una mail settimanale",
	"I don\'t want to receive any notifications": "Non voglio ricevere notifiche",
	"(we will send you a verification email)": "(Ti invieremo una mail di conferma)",
	"Current Password": "Password Attuale",
	"New Password": "Nuova Password",
	"Enter valid email": "Inserisci una email valida",
	"Repeat New Password": "Ripeti la nuova Password",
	"Save Settings": "Salva Impostazioni",
	"Passwords should match": "La password deve corrispondere",
	"Password length should be minimum 5 characters": "La lunghezza della password deve essere di almeno 5 caratteri",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Attenzione: Il tuo nuovo indirizzo email deve essere verificato cliccando sul link che ti abbiamo appena inviato.",
	"I want to receive notifications by email": "Voglio ricevere notifiche nella mia mail",
	"Status": "Status",
	"Renewal": "Rinnovare",
	"Auto renewal Canceled": "Canceled autorrenuevo",
	"Delete profile": "Eliminare profilo",
	"Your account has been deleted. You will be redirected.": "Il tuo account è stato eliminato. Sarai reindirizzato.",
	"Successfully updated": "Impostazioni correttamente aggiornate",
	"Current account status": "Stato attuale del profilo",
	"Are you sure you want to cancel your subscription?": "Sei sicuro di voler annullare il tuo abbonamento?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Il tuo abbonamento è stato cancellato con successo e non verranno addebitati ulteriori costi",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Per motivi tecnici, la cancellazione del rinnovo dell'abbonamento di prova deve essere effettuato dopo 8 ore dall'acquisto. Per qualsiasi altro tipo di abbonamento, la cancellazione deve essere effettuata dopo 8 ore dall'acquisto e 48 ore prima della sua scadenza.",
	"Profile deleted. Redirecting...": "Profilo eliminato. Reindirizzando ...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Gli abbonamenti attivi eliminati in questo modo non saranno rimborsati.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Sei sicuro di voler eliminare il tuo account? Le Tue informazioni saranno cancellate permanentemente.",
	"Settings successfully updated": "Impostazioni aggiornate correttamente",
	"Disable Notification": "Disabilitare le notifiche",
	"The notifications have been successfully disabled.": "Le notifiche sono state disattivate con successo.",
	"Canceled Auto-Renewal": "Rinnovo Cancellato",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Solo i membri Vip possono contattare più di 5 utenti diversi al giorno.",
	"Upgrade your status": "Migliora il tuo stato",
	"Enjoy our exclusive benefits and make new contacts": "Godetevi i nostri vantaggi esclusivi e contatta per meno di € 0,03 al giorno",
	"Users Online": "Utenti Connessi",
	"Discreet Billing. {site_name} will not appear in the invoice": "Fatturazione discreta. {site_name} non apparirà sulla fattura",
	"Month ::: Months": "Mese ::: Mesi",
	"3 days trial": "PROVA DI 3 GIORNI",
	"Try 3 days": "Scegliere Adesso",
	"MEMBERSHIP": "ABBONAMENTO",
	"Select": "Scegliere",
	"Send messages for 3 consecutive days": "Invia messaggi per 3 giorni",
	"Only one trial subscription per user": "E’ consentito un solo abbonamento di prova per utente",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "L'abbonamento verrà automaticamente rinnovato al costo di una delle seguenti sottoscrizioni in base al pacchetto scelto in precedenza.",
	"for": "per",
	"For full access to all details of events, including how to cancel it,": "Per avere accesso completo a tutti i dettagli dell'abbonamento, incluso come annullarlo,",
	"click here": "clicca qui",
	"for terms and conditions,": "Per i termini e condizioni,",
	"All profiles are reviewed and moderated by our team.": "Tutti i profili sono rivisti e moderati dal nostro team.",
	"CURRENT PLAN": "STATO ATTUALE",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Base (nessun bonus di prova) al momento non hai alcun pacchetto attivo e il tuo stato è BASE. Per aumentare notevolmente le tue possibilità di flirtare e contattare con altri utenti, sarà necessario avere lo stato Elite",
	"MY BENEFITS": "BENEFICI",
	"{discount} cheaper": "Sconto del {discount}",
	"save": "Risparmia",
	"Create my profile": "Crea il mio profilo",
	"Read messages": "Leggere messaggi",
	"Add to Favorites": "Aggiungi ai Preferiti",
	"Make yourself visible to other users by adding them to favorites": "Fatti conoscere dagli altri utenti aggiungendoli ai tuoi preferiti",
	"Photo Album": "Album foto",
	"No fake pictures allowed": "No si permettono foto false",
	"Album pictures": "Foto dell’album",
	"Show yourself and increase your possibilities": "Mostrati e aumenta le tue possibilità",
	"Detailed profile search": "Ricerca dettagliata del profilo",
	"Find exactly what you are looking for": "Trova esattamente quello che stai cercando",
	"See other user's pics": "Guarda le foto di altri utenti",
	"Choose your favorite": "Scegli chi ti piace di più",
	"Reply to and send messages": "Inviare o rispondere ai messaggi",
	"Make direct contact with up to five users per day": "Contatta direttamente con un massimo di cinque utenti diversi al giorno",
	"Be shown first in searches": "Apparire primo nelle ricerche",
	"Your profile will be featured before the rest of the users": "Il tuo profilo sarà evidenziato dal resto degli utenti",
	"Make direct contact with unlimited users": "Contatta direttamente con utenti illimitati",
	"Choose payment method": "Scegli il metodo di pagamento",
	"LESS THAN": "MENO DI",
	"PER DAY": "AL GIORNO",
	"DISCRETION": "DISCREZIONE",
	"Our website name will not be disclosed": "Il nostro nome non verrà visualizzato sulla fattura",
	"Our name will not be disclosed": "Il nostro nome non verrà visualizzato sulla fattura",
	"100% Anonymous.": "100% Anonimo.",
	"Choose your subscription": "Scegli il tuo abbonamento",
	"Recommended": "Raccomandato",
	"Most Popular": "Più popolare",
	"Secure payments with": "Pagamenti sicuri con",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Il tuo messaggio NON è stato inviato. Migliora il tuo stato in modo da poter inviare messaggi.",
	"Your purchase has been successful": "il tuo acquisto è stato effettuato con successo",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Per garantire un servizio ottimale e un utilizzo ininterrotto, tutti gli abbonamenti si rinnovano in automatico. Se vuoi cambiare la configurazione è possibile farlo",
	"here.": "qui.",
	"after_here": "",

	//ad
	"Become a": "Diventa",
	"PREMIUM MEMBER": "MEMBRO PREMIUM",
	"And contact": "contatta",
	"more people": "gente",

	"upgrade": "Inizia ora",
	"Boost": "Migliora",
	"boost": "Migliora",
	"your profile": "il tuo profilo",
	"Start chatting and meet more people": "Chatta e incontra gente",
	"And get": "E avrai",
	"more views": "maggiori visite",

	"Now +3 extra Credits with each purchase": "Solo ora +3 crediti in regalo su ogni acquisto",
	"Best Seller": "Il più venduto",
	"Select your Package": "Scegli il tuo pacchetto",
	"Less than {amount} per credit": "Meno di {amount} a credito",
	"Less than": "Meno di",
	"per credit": "a credito",
	"Free credits": "Crediti gratis",
	"per purchase": "con ogni acquisto",
	"Premium Status doesn’t allow free messages.": "Lo stato Premium non dà la possibilita di inviare messaggi gratis.",
	"Chat now": "Chatta ora",
	"Get Credits": "Ottenere Crediti",
	"Credit": "Credit",
	"Credits": "Crediti",
	"My credits": "I miei crediti",
	"{count} Credits left": "{count} Crediti disponibili",
	"Your message has not been sent, you need credits to be able to send messages.": "Il tuo messaggio non è stato inviato. C'è bisogno di crediti per inviare messaggi.",
	"Icebreaker": "Rompighiaccio",
	"Icebreaker today": "Rompighiaccio oggi",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Il costo per inviare uno Rompighiaccio è di 2 crediti. Il Rompighiaccio si può utilizzare solo per iniziare una conversazione o in risposta ad un altro rompighiaccio.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Con l'acquisto di ogni Pack, gli utenti effettueranno un acquisto unico di un pacchetto di crediti. Gli utenti possono utilizzare questi crediti per inviare messaggi. Il rapporto dei crediti per messaggio è di 10 a 1, ovvero, l'utente dovrà avere a disposizione almeno dieci crediti sul suo conto per inviare un messaggio. Il costo per inviare uno Rompighiaccio è di 2 crediti.",

	"Trial pack": "Pacchetto di prova",
	"Only one trial pack per user allowed": "È ammesso solo un pacchetto di prova per utente",
	"Used": "Utilizzato",

	"Exclusive Promotion": "Promozione Esclusiva",
	"Account Deleted": "Account Cancellato",
	"Log out Successful": "Log out Eseguito",

	"Do you like FREE LIVE SEX?": "Ti piace il SESSO IN DIRETTA e GRATUITO?",
	"Enter this code to enjoy a 10% discount:": "Approfitta del 10% di sconto utilizzando il codice:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Questo codice è valido per le prossime 6 ore, per il primo acquisto realizzato nella pagina.",

	"This account has been deleted.": "Questo account è stato eliminato.",
	"Our algorithm has found a better option in line with your taste.": "Il nostro algoritmo ha trovato un'opzione migliore per i tuoi gusti.",
	"You will be redirected in a few seconds.": "Verrai reindirizzato in pochi istanti.",

	"Has added you to favorites": "Ti ha aggiunto ai Preferiti",
	"Is looking at your profile": "Sta guardando il tuo profilo",
	"See profile": "Vedi profilo",
	"See message": "Vedi messagio",

	"I think I am in love": "Credo che mi sto innamorando",
	"Hey! I'm winking at you": "Quest'occhiolino è per te",
	"Tell me more about you. Fancy a chat?": "Dimmi qualcosa di te. Chattiamo?",
	"Some flowers for you!": "Un fiore per te!",
	"Sending you a kiss": "Ti mando un bacio",
	"Surprise, it\'s me!": "Sorpresa, sono io!",
	"Hi, how are you?": "Ciao, come stai?",
	"Let's get naughty": "Andiamo oltre...",

	"Get 5 times the regular response rate. Premium members benefits:": "Moltiplica per cinque le tue possibilitá di azione. I vantaggi degli utenti premium sono:",
	"Appear high up on searches": "Apparire in alto nella ricerca",
	"Distinctive badge that increases your visibility": "Segno distintivo per incrementare la visibilità",
	"5 Free Icebreakers a day": "5 Rompighiaccio gratis al giorno",
	"In addition to this, a voucher for a 15% Webcam discount": "In aggiunta, un buono sconto del 15% sulle webcam",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Il Rompighiaccio si può utilizzare solo per iniziare una conversazione o in risposta ad un altro rompighiaccio.",

	"Succesful purchase": "Acquisto riuscito",
	"Your purchase has been successful.": "Il tuo acquisto è stato realizzato con successo.",
	"It can take a few minutes to update.": "Lo potrai visualizzare tra qualche minuto.",
	"You will be redirected.": "Verrai reindirizzato.",

	"Changes can take some minutes to update.": "Le modifiche si aggiorneranno tra qualche minuto.",

	"Yearly Package": "Pacchetto annuale",
	"Package": "Pacchetto",
	"Buy Now": "Acquista Ora",
	"Buy Now": "Acheter maintenant",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Lo stato permette di contattare fino a 5 Utenti diversi ogni giorno, con messaggi illimitati.",

	"Exclusive offer": "Offerta esclusiva",
	"Welcome": "Benvenuti",
	"Mary from {domain_name} support": "Bianca di supporto {domain_name}",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Benvenuti su {domain_name}. Ti auguriamo di incontrare quello che  cerchi.",
	"Here are some Pro Tips to be more successful and meet people:": "Qui di seguito indichiamo alcuni consigli per evere più successo e incontrare persone nuove:",
	"Upload a profile picture to get more connections.": "Carica una foto profilo per attirare più contatti.",
	"Send an icebreaker to get a conversation easily started.": "Manda un rompighiaccio per iniziare la conversazione più facilmente.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Se ti interessa qualcuno non dimenticare di aggiugerlo ai tuoi preferiti per far sapere che c'è interesse.",
	"And of course, if you like somebody send a message and ask for a date!": "E ovviamente se ti piace qualcuno manda un messaggio e chiedi un appuntamento.",
	"If you need any extra help, please contact our ": "Se hai bisogno di ulteriore supporto, non esitare a contattare il nostro ",
	"support": "servizio clienti",
	". We will be happy to help you.": ". saremo lieti di aiutarti",
	"Try your luck": "Prova la fortuna",
	"64% of our users have a date in the first 2 weeks.": "il 64% dei nostri utenti ha un appuntamento nelle prime 2 settimane.",
	"Good Luck!.": "Buona fortuna!",
	"*This message will be deleted upon closing.": "*Questo messaggio si cancellerà alla chiusura.",

	"Private picture": "Foto private",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Le foto private appariranno sfuocate, possono essere visualizzate solo dagli utenti che avranno effettuato un pagamento.",
	"Name change": "Modifica nome",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Se desidera cambiare il nome o nickname del profilo contatti il nostro supporto tecnico, che effettuerà la modifica in meno di 24 ore.",
	"See all images": "Mostra tutte le foto",
	"Unlock all private photos forever for just 20 credits": "Sblocca le foto private in modo permanente a soli 20 crediti",
	"Unlock forever all private pictures": "Sblocca le foto private in modo permanente",

	"This email address is not registered, please sign up to log in.": "Questa mail non è registrata, per favore registrati per poter fare il login.",
	"Congratulations, your account is now active.": "Congratulazioni il tuo account è attivo.",
	"Complete one last step and have fun!": "Completa l'ultimo step e divertiti!",
	"OR": "O",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Ti abbiamo inviato una mail con un link per attivare il tuo account che sarà valido per le prossime 12 ore:",
	"If you haven\'t received it in your inbox or ": "Se non la trovi nella tua posta ricevuta o in quella di ",
	"or you want to modify the email address, click on the button below.": "o vuoi modificare il tuo indirizzo di posta elettronica, clicca sul bottone qui sotto.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Ti abbiamo inviato una mail per verificare il tuo account valida per le prossime 12 ore:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Se non la trovi nella tua casella di posta in arrivo o nello spam, puoi modificarlo o reinviarlo",
	"Resend or change email": "Rinviare o modificare email",
	"Please use the same email you registered.": "Seleziona la stessa email di registrazione.",
	"Connect with Google": "Accedi con Google",
	"Connect with Yahoo": "Registrati con Yahoo",
	"Connect with Microsoft": "Accedi con Hotmail",
	"Or turn back": "Torna indietro",
	"Complete your registration": "Completa la tua registrazione",

	"It will not be shared": "Non sarà condiviso",
	"It will never be shared. 100% Privacy": "Non sarà condiviso. 100% privacy",

	"Unlock all private pictures forever.": "Sblocca le foto private in modo permanente.",
	"Unlock": "Sblocca",

	"You will be redirected in a second": "Verrai reindirizzato in pochi secondi",

	"Card payment": "Pagamento con carta",
	"Bank transfer": "Bonifico Bancario",
	"Prepaid card": " Carta prepagata",
	"SofortBanking": "Bonifico Bancario",

	"Error": "Errore",
	"page not found": "Pagina non trovata",
	"Back to homepage": "Torna alla Home",

	"per month": "al mese",
	"week": "settimana",
	"{count} week": "{count} settimana",

	"Your profile has been blocked": "Il tuo profilo è stato bloccato",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Per offrire una migliore esperienza ai nostri utenti, blocchiamo i profili che non hanno inviato nessun messaggio dopo 21 giorni della loro attivazione.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Per non perdere il tuo profilo e utilizzare tutte le sue funzioni è necessario acquistare un pacchetto crediti.",
	"Restore access to:": "Accedi di nuovo a:",
	"All messages": "Tutti i messaggi",
	"Users search": "Cerca utenti",
	"Your contacts": "I tuoi contatti",
	"All favourites": "Tutti i tuoi preferiti",
	"All pictures": "Tutte le foto",
	"Send Icebreakers": "Invia Rompighiaccio",
	"Unblock Profile": "Sblocca profilo",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Questo sito Web utilizza cookie e identificatori propri e di terze parti per migliorare l'accessibilità e analizzare il nostro traffico. Se continui a navigare, assumeremo che accetti questi cookie. Per ulteriori informazioni, consulta la",
	"Cookies Policy": "Politica sui cookie",

	"DATE GUARANTEE": "GARANZIA PER APPUNTAMENTI",
	"Take advantage of our Date guarantee": "Approfitta dei vantaggi della nostra garanzia per appuntamenti",
	"No date? Money Back!": "Nessun appuntamento? ti rimborsiamo!",
	"No risk": "Senza rischi",
	"Guarantee of success": "Successo garantito",
	"Terms and Conditions": "Termini e condizioni",

	"Boost your visibility": "Aumenta la visibilità del profilo",
	"SAVE": "RISPARMIA",
	"Add 3 months premium": "Aggiungi 3 mesi premium",
	"Add {months} months premium": "Aggiungi {months} mesi premium",

	"We guarantee you a real date.": "Sul nostro sito ti diamo la garanzia di realizzare un incontro reale.",
	"YES, I WANT A DATE": "SI, VOGLIO UN APPUNTAMENTO",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Termini e condizioni per la garanzia di appuntamenti. Per avere diritto al rimborso, devi essere in possesso di un profilo completo in ogni sua parte e di una descrizione durante almeno sei mesi. Il profilo deve avere almeno una foto approvata durante questo periodo. Inoltre ogni settimana devi aver avuto conversazioni con almeno 7 persone diverse posizionati in città vicino a te (in un raggio di 100 km) e che rientrino nella tua fascia età (-5/+5 anni). Se tutte queste condizioni sono soddisfatte, e non hai potuto ottenere un appuntamento ti rimborseremo tutti i pagamenti realizzati fino a quel momento. Questa garanzia è valida solo 12 mesi dopo la tua registrazione. Buona fortuna.",

	"You have {count} days premium left": "Ti restano ancora {count} giorni premium",
	"Check our special offer before deleting your account!": "Approfitta delle nostre offerte speciali prima di cancellare il tuo profilo",
	"Delete my account": "Cancellare account",
	"Free": "Gratuito",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Il Pacchetto Premium, Elite e VIP si rinnovano automaticamente ovvero alla scadenza l'importo verrà addebitato automaticamente.",
	"and can confirm that I am 18 years or older.": "e confermo di essere maggiorenne.",

	"COMPLETE YOUR PURCHASE": "COMPLETA IL TUO ACQUISTO",
	"Payment details": "Dettagli del pagamento",
	"Total": "Totale",
	"3-digit number on the back of your credit card": "Number di 3 cifre situato sul retro della carta",
	"MM / YY": "MM / AA",
	"MM": "MM",
	"YY": "AA",
	"Name on card": "Nome sulla carta",
	"Charges will appear discreetly as tpmpsup.com": "I costi compariranno in modalitá riservata come tpmpsup.com",
	"Buy Now": "Comprare Ora",
	"Premium package 3 Months": "Pacchetto Premium 3 mesi",

	"This purchase was declined. Please check your details and try again.": "Il tuo acquisto è stato rifiutato. Per favore controlla i tuoi dati e riprova nuovamente.",
	"Your purchase was succesful.": "Il tuo acquisto ha avuto successo.",

	"SPECIAL OFFER": "OFFERTA SPECIALE",
	"Unlock your account with our special offer": "Sblocca il tuo account con la nostra offerta speciale",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Approfitta della nostra offerta speciale per sbloccare il tuo account e recuperare le sue proprietà.",

	"card_number_require": "Numero carta obbligatorio",
	"card_number_invalid": "Numero carta non valido",
	"card_name_require": "Titolare carta obbligatorio",
	"card_name_invalid": "Titolare della carta non riconosciuto",
	"card_cvc_require": "CVV obbligatoro",
	"card_cvc_invalid": "CVV non valido",
	"card_expiry_require": "data di scadenza della carta obbligatorio",
	"card_expiry_invalid": "la data di scadenza della carta non è valida",

	"Private photo": "Foto privata",
	"Search for someone now": "Cerca gente adesso",
	"Edit": "Modificare",

	"Locked Album": "Album protetto",
	"Get credits to unlock all albums": "Album bloccato, acquista crediti per sbloccare tutti gli album",
	"picture ::: pictures": "foto ::: foto",
	"Open": "Apri",

	"Receive notifications": "Ricevere notifiche",
	"I want to be notified when I receive a message": "Voglio ricevere una notifica quando mi arriva un messaggio",
	"No": "No",
	"Yes": "Si",

	"logout_confirm_title": "Disconnettere",
	"Are you sure you want to log out?": "Sei sicuro che vuoi chiudere la sessione?",

	"Your payment has been cancelled": "Il tuo pagamento è stato annullato",
	"Something went wrong, please try again in 10 minutes": "Si è verificato un errore, per favore riprova tra 10 minuti",

	"Please accept the terms and conditions to continue": "Si prega di accettare i termini e le condizioni prima di continuare",
	"By checking this box and clicking continue I accept the": "Selezionando questa casella e facendo click su continua accetto",
	"rg_terms": "i Termini e le Condizioni",
	"rg_privacy": "la Politica sulla privacy",
	"rg_cookies": "i Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "sono consapevole dell’uso di profili fittizi nella pagina e confermo di essere maggiorenne.",

	"Cookie": "Cookie",
	"Responsible Party": "Responsabile",
	"Category": "Responsabile",
	"Cookie Name": "Nome",
	"Purpose": "Scopo",
	"Lifespan": "Durata",
	"Type of Cookie": "Tipo di cookie",
	"Strictly necessary": "Strettamente necessario",
	"Functional": "Funzionale",
	"Performance": "Performance",
	"Advertising": "Advertising",
	"cookie-policy": "politica-dei-cookie",
	"cookie policy": "politica dei cookie",

	"We Care About Your Privacy": "Ci teniamo alla tua privacy",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Questo sito utilizza cookie propri, tecnici e di terze parti per migliorare l'esperienza del browser, analizzare il comportamento dei visitatori e aiutare i nostri partner e noi stessi ad analizzare le campagne di marketing e pubblicità personalizzata. Cliccando su \"Accetta tutto\" accetti tutti questi cookie.",
	"Accept All": "Accetto tutto",
	"Cookies Settings": "Impostazione dei cookie",
	"About your privacy": "Informazioni sulla tua privacy",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Utilizziamo i cookie per il bilanciamento del traffico, sessioni di accesso e analisi di marketing. Puoi contrassegnare o deselezionare i cookie che desideri nel pannello che appare di seguito.",
	"You will be able to change your set up at any time from our :cookies_policy page": "È possibile modificare le preferenze nelle nostre pagine sulla :cookies_policy",
	"Accept all": "Accetto tutto",
	"Manage Consent Preferences": "Imposta le preferenze per il consenso",
	"Technical (Mandatory)": "Tecnico (strettamente necessario)",
	"Performance (Monitor website performance)": "Performance (monitorare la performance del sito)",
	"Functional (User experience improvement)": "Funzionale (migliorare l'esperienza utente)",
	"Advertising (Marketing measurement)": "Pubblicità (analisi di marketing)",
    "Advertising (Personalization)": "Pubblicità (personalizzazione)",
	"Save Selection": "Salva le modifiche",
	"Reject All": "Annullare tutto",

	"We and our partners process data to provide:": "Noi e i nostri partner elaboriamo i dati per fornire:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Utilizza dati di geolocalizzazione precisi. Scansione attiva delle caratteristiche del dispositivo per l'identificazione. Memorizzazione e/o accesso alle informazioni su un dispositivo. Annunci e contenuti personalizzati, misurazione di annunci e contenuti, approfondimenti sul target e sviluppo del prodotto.",
	"List of partners": "Elenco dei partner",
	"Cookie Policy": "Politica dei cookie",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Questi cookie vengono utilizzati in relazione al tracciamento delle azioni relative agli annunci pubblicitari. Ad esempio, questi cookie memorizzeranno che hai visitato {name} e possono essere utilizzati per mostrarti una pubblicità personalizzata su un altro sito web. Si prega di controllare i seguenti collegamenti delle piattaforme in cui promuoviamo il nostro sito, (Google, Microsoft e Twitter) per maggiori informazioni.",
	"Delete cookies": "Cookies",
	"Save selection": "Salva le modifiche",
	"Reject all": "Annullare tutto",

	"{name} hasn't replied to you yet. Send another message!": "{name} Non ti ha ancora risposto. Inviale un altro messaggio!",
	"Validate my Ice-breaker": "Confermo il mio rompighiaccio",

	"Distance": "Distanza",
	"now": "ora",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "A partire dal 1 di giugno 2023 non sarà più possibile effettuare pagamenti con PaySafeCard.",

	"Dear User,": "Gentile cliente,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Grazie per il tuo interesse ad unirti al nostro sito web, ma al momento la registrazione è chiusa per gli uomini in quanto dobbiamo trovare un equilibrio di genere vicino al 50%.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Se vuoi ancora uscire con qualcuno, fai clic sul pulsante e prova uno dei migliori siti Web online.",
	"Try Now": "Prova ora",

	"Your account has been deleted.": "il tuo account è stato cancellato",
	"According to your choices, our algorithms have found a better option for you.": "Il nostro algoritmo ha trovato un'opzione migliore per te",
	"See More": "Mostra di più",

	"10 credits for you!": "10 crediti per te!",
	"Get 3 free Icebreakers": "Ottieni 3 rompighiaccio gratuiti",
	"Free Icebreakers": "Rompighiaccio gratuiti",
	"Congratulations, you have received 3 Icebreakers!": "Congratulazioni, hai ricevuto 3 rompighiaccio",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay funziona solo dal browser Safari. Per favore apri Safari e  accedi al tuo conto per poter effettuare il tuo pagamento.",

	"No nudity": "Senza nudo",
	"Nudity allowed": "Nudo concesso",

	"Edit profile": "Modifica Profilo ",
	"Online": "Online",
	"Step": "Avanti",
	"Completed": "Completato",
	"Help": "Aiuto",
	"or Drag and drop": "o trascina e rilascia",

	"You will be able to send messages in": "Potrai inviare messaggi in",
	"Send": "Inviare",
	"Success": "Successo",
	"Start a conversation with {name}": "Inizia una conversazione con {name}",
	"Failed": "Fallito",
	"Congratulations, your account has been successfully created.": "Congratulazioni, il tuo account è stato creato con successo.",
	"Contact our support": "Contatta il nostro supporto",

	"About": "Sopra",
	"Scale": "Impostazioni",
	"Rotate": "Girare",

	"No favorites added": "Non hai favoriti",

	"Enter Email": "Inserisci mail",

    "We have sent you an email to:": "Ti abbiamo inviato un'e-mail all’indirizzo:",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Conferma facendo clic sul pulsante “Elimina account\" in quell'e-mail e elimineremo definitivamente il tuo account.",
    "DELETE ACCOUNT": "Elimina account",

	"Didn\'t find what you were looking for?": "Non riesci a trovare quello che stavi cercando?",
	"Please send us an email and we will get back to you.": "Inviaci un'e-mail e ti risponderemo il prima possibile.",
	"Or contact us using the form": "Oppure contattaci utilizzando il modulo.",

	"BOOST YOUR ACCOUNT": "POTENZIA IL TUO ACCOUNT",
	"We will send you an email to boost your account:": "Ti invieremo un'e-mail per potenziare il tuo account:",
	"Get Free exclusive benefits": "Ottieni vantaggi esclusivi gratuiti",
	"Premium Status 24h": "Status Premium 24h",
	"5 Free Credits": "5 Crediti Gratuiti",
	"3 Free Icebreakers": "3 Rompighiaccio gratuiti",
	"IMPROVE ACCOUNT": "MIGLIORA L'ACCOUNT",

	"COMPLETE REGISTRATION": "COMPLETA REGISTRAZIONE",
	"Confirm your email and complete registration": "Conferma la tua Email e completa la registrazione",
	"Benefits": "Vantaggi",
	"More Visibility": "Più visibilità",
	"Send Messages": "Invia messaggi",

    "Your registration is completed": "Hai completato la registrazione",
}
