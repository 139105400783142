export default {
	"Already a member?": "Allerede medlem?",
	"Login": "Logg inn",
	"Log out": "Logg ut",
	"Your current status": "Din nåværende status",
	"BASIC": "BASIC",
	"Basic": "Basis",
	"Bronze": "Bronse",
	"Elite": "Elite",
	"VIP": "VIP",
	// "Join Us": "Entra ahora",
	// "Find people near you": "Encuentra gente cerca de ti",
	"Join now": "Søk nå",
	"I am a": "Jeg er",
	"Email": "E-post",
	"It will not be published": "Skriv inn din e-postadresse",
	"E-mail address": "Skriv inn din e-postadresse",
	"SEARCH NOW": "Bli medlem gratis",
	"I confirm that I am over 18 years old": "Jeg bekrefter at jeg er over 18 år gammel",
	// "Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "No lo pienses más y entra al fenómeno social del momento. Cada día miles de personas como tú se unen a nosotros.",
	"Information": "Informasjon",
	"All rights reserved.": "All rights reserved.",
	"SUBSCRIPTION": "STATUS",
	"Other": "Annet",
	"All fields are required": "Alle felt må fylles ut",
	"The fields marked in Red are required. Please fill them to continue.": "Feltene merket med rødt er påkrevd. Vennligst fyll ut for å fortsette.",
	"If you did not find a suitable answer please contact us": "Hvis du ikke fant et passende emne, vennligst ta kontakt med oss",
	"Will help us to identify you": "Vil hjelpe oss å identifisere deg",
	"Invalid Username": "Ugyldig brukernavn",
	"Subject": "Emne",
	"Tell us how can we help you": "Fortell oss hvordan vi kan hjelpe deg",
	"Please enter subject": "Vennligst skriv inn emne",
	"Please enter at least 10 characters": "Vennligst skriv inn minst ti tegn",
	"Connected to your account": "Koblet til din konto",
	"Repeat Email": "Gjenta e-post",
	"Emails did not match": "E-post matcher ikke",
	"Message": "Melding",
	"No Recent Activity": "Ingen nylig aktivitet",

	//route labels
	"Home": "Hjem",
	"Contact": "Kontakt",
	"Support": "Hjelp",
	"Affiliates": "Affiliates",
	"Privacy": "Personvern",
	"Terms": "Vilkår for bruk",

	//routes uri
	"/login": "/logg-inn",
	"/contact": "/kontakt",
	"/support": "/kontakt",
	"/affiliates": "/Affiliate-program",
	"/privacy": "/privacy",
	"/terms-and-conditions": "/generelle-vilkar-og-betingelser",
	"/cookie-policy": "/informasjonskapsel-policy",
	"/subscription/exit": "/betaling/exit",
	"/purchase-confirmation": "/bekreft-kjop",
	"/page/:page_name": "/side/:page_name",

	"/dashboard": "/dashbord",
	"/mobile-dashboard": "/mobil-dashboard",
	"/activate-profile/:token": "/aktiver/:token",
	"/edit-profile": "/rediger-profil",
	"/profile/:name": "/profil/:name",
	"/my-picture": "/mine-bilder",
	"/messages/inbox": "/meldinger/innboks",
	"/messages/trash": "/meldinger/soppel",
	"/messages/send/:name": "/meldinger/send/:name",
	"/visitor": "/besokendes",
	"/favorite/my": "/favoritt/min",
	"/favorite/others": "/favoritt/andre",
	"/search": "/sok",
	"/search-result": "/sokeresultat",
	"/settings": "/innstillinger",
	"/validate-new-email-address/:token": "/sjekk-ny-e-postadresse/:token",
	"/subscription": "/betaling",
	"/credits": "/kreditter",
	"/elite": "/elite",
	"/faq": "/faq",
	"/delete-account": "/konto-slettet",

	"/exclusive-promotion": "/eksklusivt-tilbud",
	"/account-deleted": "/kontoen-er-slettet",
	"/logged-out": "/logg-ut",
    "/complete-register/:token" : '/fullfor-registrering/:token',

	//login
	"Login": "Logg inn",
	"Enter username or Email": "Skriv inn brukernavn eller e-post",
	"Enter Password": "Skriv inn passord",
	"Username": "Brukernavn",
	"Nickname": "Brukernavn",
	"Password": "Mot de passe",
	"I forgot my password": "Jeg har glemt passordet mitt",
	"Password Reminder": "Passord påminner",
	"Enter you email": "Skriv inn din e-post",
	"Your email address": "Din e-postadresse",
	"Don't have an account?": "Har du ikke konto?",
	"REGISTER": "REGISTRER DEG",
	"Password sent to your email": "Passord sendt til din e-post",
	"This account has not been validated yet": "Denne kontoen er enda ikke aktivert",
	"LOGIN USING YOUR CREDENTIALS": "SKRIV INN DINE BRUKERDATA",
	"FORGOT YOUR PASSWORD?": "HAR DU GLEMT PASSORDET DITT?",
	"Login failed": "Innloggingen feilet",
	"Username or password is incorrect": "Feil brukernavn eller passord",

	"We're sorry!": "Vi beklager",
	//registration
	"You're almost done!": "Du er nesten ferdig!",
	"Please fill your additional account information": "Vennligst fyll inn tilleggsinformasjon",
	"Just one step left!": "Bare ett steg igjen!",
	"Let us learn more about you. Fill in your personal data": "La oss finne ut mer om deg. Fyll inn dine personlige data",
	"Activate your account now": "Aktiver din konto nå!",
	"Activate your account now.": "Aktiver din konto nå!",
	"Please confirm your e-mail address": "Vennligst bekreft din e-postadresse",
	"Please fill in the following form to join {site_name} for free": "Vennligst fyll ut følgende skjema for å få ditt gratis medlemskap på {site_name}",
	"CONTINUE": "FORTSETT",

	"By clicking on the continue button I agree with the": "Ved å klikke \"Fortsett\" aksepterer du",
	"terms": "vilkår og betingelser",
	"and cookies": "og cookies",

	"This site is protected by reCaptcha and the Google": "Dette nettstedet er beskyttet av reCAPTCHA, og Googles",
	"Privacy Policy": "personvernregler",
	"and": "og",
	"Terms of Service": "vilkår for bruk",
	"apply.": "gjelder.",

	"Personal description": "Personlig beskrivelse",
	"optional": "valgfri",
	"FINALIZE": "FULLFØR",
	"We have just sent you a confirmation e-mail at": "Vi har sendt en bekreftelses e-post til",
	"To start using our website, please click on the activation link in this email.": "For å starte og bruke websiden, vennligst klikk på aktiveringslinken i denne e-posten.",
	"Click here to verify your email": "Klikk her for å verifisere din e-post",
	"CLICK TO ACTIVATE": "KLIKK FOR Å AKTIVERE",
	"CLICK TO ACTIVATE - desktop": "KLIKK FOR Å AKTIVERE",
	"VERIFY MY EMAIL": "VALIDER EPOST",
	"Not received any email yet?": "Ikke mottatt noen e-post?",
	"Please check your": "Vennligst sjekk din",
	"spam": "søppel",
	"box and wait at least 10 minutes or re-send activation": "post og vent minst 10 minutter eller re-send aktivering.",
	"Enter Nickname": "Skriv inn brukernavn",
	"Enter Your Name": "Skriv inn navnet ditt",
	"Password Required": "Passord er obligatorisk",
	"Enter valid Email": "Skriv inn gyldig e-postadresse",
	"Please enter at least 5 characters": "Vennligst skriv inn 5 tegn",
	"Enter strong password": "Skriv inn et sikkert passord",
	"Email not available": "Email ikke tilgjengelig",
	"Nickname not available": "Brukernavnet er ikke tilgjengelig",
	"Maximum 4 number allowed": "Maks 4 tall er tillatt",
	"Name should not contain any space, number or special character": "Navnet kan ikke inneholdet mellomrom, tall eller spesialtegn",
	"Nickname should not contain any space or special characters": "Brukernavnet kan ikke inneholde mellomrom eller spesialtegn",
	"Your activation mail has been sent": "En aktiveringsmail er sendt til din e-postadresse",
	"Complete your profile in a minute and start contacting people": "Fullfør din profil på ett minutt og kom i kontakt med andre medlemmer",
	"Complete your profile": "fullfør din profil",
	"Create Profile": "Opprett profil",
	"Your description": "Din beskrivelse",
	"Confirm your Email": "Bekreft din e-postadresse",
	"We have sent you an email.": "Vi har sendt deg en e-post.",
	"Check your email to complete your registration": "Sjekk din e-post for å fullføre registreringen",
	"and be able to speak with other users.": "og få muligheten til å kontakte andre brukere.",
	"If you can not find it look it up in the spam or": "Hvis du ikke kan finne den, sjekk spam eller",
	"If you have not received it select one of the following options": "Hvis du ikke har mottatt den, velg ett av følgende alternativer",
	"junk folder": "søppelpost",
	"Change Email": "Endre e-post",
	"Resend": "Send på nytt",
	"New Email": "Ny e-post",
	"Your activation key is invalid": "Din aktiveringsnøkkel er ugyldig",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Vennligst inkluder en '@' i epost adressen. '{email}' mangler en @.",

	//public pages
	"privacy": "Personvern",
	"terms-and-conditions": "generelle-vilkår-og-betingelser",
	"about-us": "om-oss",

	//forms
	"Name": "Navn",
	"Marital Status": "Sivilstatus",
	"Date of Birth": "Alder",
	"Country": "Land",
	"Location": "Fylke",
	"Province": "Fylke",
	"City": "By",
	"Save Changes": "Lagre endringer",
	"Select from list": "Velg fra listen",
	"Day": "Dag",
	"Month": "Måned",
	"month": "mnd",
	"Year": "År",
	"Prefer not to say": " vil ikke oppgi",
	"Only profiles with photo": "Kun profiler med bilde",
	"This field is required": "Dette feltet er påkrevd",
	"Please update your profile": "Vennligst oppdater din profil",
	"Profile successfully updated": "Profil vellykket oppdatert",
	"The fields marked in red are required. Please fill them to continue.": "Feltene merket med rødt er påkrevd. Vennligst fyll ut for å fortsette.",
	"Characters left": "tegn igjen",
	"Image attached": "Bilde lagt til",
	"The message field is required.": "Meldingsfeltet er påkrevd.",
	"WITH PICTURE": "MED BILDE",
	"all age groups": "Alle aldersgrupper",
	"EDIT DESCRIPTION": "ENDRE BESKRIVELSE",
	"DESCRIPTION": "BESKRIVELSE",
	"FEATURING": "Personlighet",
	"Please Select": "Vennligst velg",
	"Emails do not match": "E-post matcher ikke",
	"Your request was succesfully sent!": "Din forespørsel ble sendt!",
	"Or send us a message directly": "Eller send oss en epost",

	//user pages
	"My Profile": "Min Profil",
	"Add Photos": "Legg til bilder",
	"Messages": "Meldinger",
	"Favorites": "Favoritter",
	"Search": "Søk",
	"Settings": "Innstillinger",
	"Visits": "Besøkende",
	"Likes": "Favoritt hos andre",
	"Membership": "Status",
	"Vip Members": "VIP Medlemmer",
	"Profile_mobile_menu": "Profil",

	"Account activation successful": "Kontoaktivering vellykket",
	"My chats": "Mine meldinger",

	//user home
	"Quick Search": "Raskt søk",
	"More Options": "Flere valg",
	"I am": "Jeg er",
	"Looking for": "Søker",
	"Age": "Alder",
	"Search for": "Søk",
	"All age groups": "Alle aldersgrupper",
	"Popular Members": "Populære medlemmer",
	"New Members": "Nye medlemmer",
	"Find a Match": "Finn en match",
	"Recent Activity": "Nylig aktivitet",
	"Add Photo": "Legg til bilde",
	"Back": "Tilbake",
	"Become Elite": "Bli Elite medlem",
	"Upgrade": "Oppgrader",

	"likes": "BETATT",
	"EDIT MY PROFILE": "REDIGER PROFIL",
	"CONFIGURATION": "INNSTILLINGER",
	"ONLINE SUPPORT": "ONLINE SUPPORT",

	//user message
	"Trash": "Søppel",
	"Delete Selected": "Slett valgte",
	"No Messages Available": "Ingen meldinger tilgjengelig",
	"No Messages selected": "Ingen meldinger valgt",
	"Back to inbox": "Tilbake til innboks",
	"Back to messages": "Tilbake til meldinger",
	"Select all": "Marker alle",
	"Deselect all": "Opphev markering",
	"Next Page": "Neste side",
	"Prev Page": "Forrige side",
	"Type your message here": "Skriv din melding her",
	"Send message": "Send melding",
	"Send Icebreaker": "Send Icebreaker",
	"VIEW PROFILE": "VIS PROFIL",
	"DELETE": "SLETT",
	"Chat with": "Chat med",
	"Chat": "Chat",

	//user favorites
	"My Favorites": "Mine favoritter",
	"Likes": "Favoritt hos andre",
	"No likes": "Ingen favoritter",
	"Favorite added successfully": "Lagt til i favoritter",
	"Favorite deleted successfully": "Slettet fra favoritter",
	"Visit Profile": "Besøk profil",

	//user visits
	"My Profile Visitors": "Hvem har besøkt min profil",
	"No Visitors": "Ingen besøkende",

	//user photos
	"My pictures": "Mine bilder",
	"See my public profile": "Se din offentlige profil",
	"Profile photo": "Profilbilde",
	"Profile Picture": "Profilbilde",
	"2MB or lower.": "2MB eller mindre.",
	"2MB or lower": "2MB eller mindre",
	"Nudes not allowed in this category.": "Nakenbilder er ikke tillatt i denne kategorien.",
	"PHOTO ALBUM": "FOTOALBUM",
	"Allowed images 2 megabytes or less": "tillatte bilder, 2 megabyte eller mindre",
	"Add picture": "Legg til bilde",
	"Main reasons for rejection": "Hovedgrunner for avvisning",
	"Are you sure?": "Er du sikker?",
	"Are you sure you want to delete this image?": "Er du sikker på at du vil slette dette bildet?",
	"Close": "Lukk",
	"Confirm": "Bekreft",
	"Attaching image": "Legger til bilde",
	"The message must be at least 2 characters.": "LMeldingen må være på minst 2 tegn.",
	"Deleting...": "Sletter...",
	"Pictures of underage people": "Bilder av mindreårige",
	"Personal data is visible": "Med personlig informasjon",
	"Fake or stolen pictures": "Fake eller stjålne bilder",
	"Different gender to profile": "Annet kjønn enn profilen",
	"Group pictures": "Gruppebilde",
	"Weapons, drugs, violence": "Våpen, rusmidler, vold",
	"No people visible": "Ingen synlige mennesker",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Vi gjennomgår bildene dine. Når de har passert verifikasjonsprosessen vil de være synlige for andre brukere.",

	//user profiles
	"Profile": "Profil",
	"yrs": "år",
	"years": "år",
	"yr from": "år fra",
	"Physique": "Kroppstype",
	"Photos": "Bilder",
	"No Photos": "Ingen bilder",
	"About Me": "Om meg",
	"Characteristics": "Karakteristika",
	"Prefers": "Foretrekker",
	"Relationship": "Status",
	"Shape": "Fasong",

	//user edit profile
	"DESCRIBE YOURSELF": "BESKRIV DEG SELV",
	"GENERAL": "GENERELT",
	"(Will be verified)": "(verifiseres manuelt)",
	"Sex": "Kjønn",
	"Seeking": "Søker",
	"Finish your profile": "Et øyeblikk",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "For å fortsette, fullfør profilen din!",

	//search profile
	"Advanced Search": "Avansert Søk",
	"OPEN TO": "ÅPEN FOR",
	"PREFERENCES": "PREFERANSER",
	"CHARACTER": "KARAKTER",
	"Search Result": "Søkeresultat",
	"No Member found": "Ingen medlemmer funnet",
	"Change Search": "Endre søk",
	"Hair Color": "Hårfarge",
	"Eye Color": "Øyenfarge",
	"Body Type": "Kroppstype",
	"Height": "Høyde",
	"Weight": "Vekt",
	"Smoker": "Røyk/snus",
	"Ethnicity": "Etnisitet",
	"From": "Fra",
	"To": "Til",

	//settings
	"Change E-mail address": "Endre e-postadresse",
	"Your email address has been successfully updated": "Din e-postadresse har blitt oppdatert",
	"Password": "Passord",
	"Account Status": "Tilin Status",
	"Cancel": "Avbryt",
	"Delete Account": "Slett konto",
	"Notifications": "Varsler",
	"Receive all notifications individually": "Motta alle varslinger individuelt",
	"Receive one daily email with all my notifications": "Motta en daglig email med alle varslingene samlet",
	"Receive a weekly email with all my notifications": "Motta en ukentlig email med alle varslingene samlet",
	"I don\'t want to receive any notifications": "Jeg vil ikke motta noen varslinger",
	"(we will send you a verification email)": "(du vil motta en verifikasjons e-post)",
	"Current Password": "Nåværende passord",
	"New Password": "Nytt passord",
	"Enter valid email": "Skriv inn gyldig e-postadresse",
	"Repeat New Password": "Gjenta nytt passord",
	"Save Settings": "Lagre innstillinger",
	"Passwords should match": "Passordene må samsvare",
	"Password length should be minimum 5 characters": "Passordet må bestå av minst fem tegn eller bokstaver",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "OBS: Din nye e-postadresse må verifiseres ved å klikke på linken vi har sendt deg.",
	"I want to receive notifications by email": "Jeg ønsker å motta varsler til min e-post",
	"Status": "Betingelser",
	"Renewal": "Fornye",
	"Auto renewal Canceled": "Automatisk fornyelse kansellert",
	"Delete profile": "Slett profil",
	"Your account has been deleted. You will be redirected.": "Din konto har blitt slettet. Du vil bli omdirigert.",
	"Successfully updated": "Vellykket oppdatert",
	"Current account status": "Tämänhetkinen Tilin Status",
	"Are you sure you want to cancel your subscription?": "Er du sikker på at du vil kansellere ditt abonnement?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Dit abonnement har blitt kansellert og vil ikke fornyes",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Av tekniske årsaker må kansellering av abonnementsfornyelse skje minimum 8 timer etter kjøp. For alle andre type betalinger må kansellering skje minst 8 timer etter kjøp og innen 48 timer før det utløper.",
	"Profile deleted. Redirecting...": "ProfProfil slettet. Omdirigerer ...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Aktive abonnementer slettet på denne måten vil ikke bli refundert.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Er du sikker på at du vil slette * kontoen din? Din informasjon vil bli permanent slettet.",
	"Settings successfully updated": "Innstillinger vellykket oppdatert",
	"Disable Notification": "Deaktiver varslinger",
	"The notifications have been successfully disabled.": "Varsler vellykket deaktivert.",
	"Canceled Auto-Renewal": "Automatisk Fornyelse Kansellert",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Kun Vip medlemmer kan kontakte mer enn fem brukere pr dag.",
	"Upgrade your status": "Steg 1: Velg ditt medlemskap",
	"Enjoy our exclusive benefits and make new contacts": "Nyt våre eksklusive fordeler og knytt kontakter for mindre enn KR 0.3 om dagen",
	"Users Online": "Brukere Online",
	"Discreet Billing. {site_name} will not appear in the invoice": "Diskret fakturering. {site_name} vil ikke vises i fakturaen",
	"Month ::: Months": "Måned ::: Måneder",
	"3 days trial": "3 dagers prøve",
	"Try 3 days": "Prøv 3 dager",
	"MEMBERSHIP": "ABONNEMENT",
	"Select": "Kjøp",
	"Send messages for 3 consecutive days": "Send meldinger i syv dager",
	"Only one trial subscription per user": "Kun et prøveabonnement pr bruker",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "Abonnementet fornyes automatisk til prisen av ett av følgende abonnement, basert på pakken valgt ovenfor",
	"for": "for",
	"For full access to all details of events, including how to cancel it,": "For full tilgang til alle detaljer i avtalen, inkludert hvordan du kansellerer den,",
	"click here": "klikk her",
	"for terms and conditions,": "for vilkår,",
	"All profiles are reviewed and moderated by our team.": "Alle profiler blir vurdert og moderert av om-osss.",
	"CURRENT PLAN": "NÅVÆRENDE ABONNEMENT",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Basic (ikke prøveabonnement) du har for tiden ingen aktiv pakke og er Basic bruker. For å øke dine sjanser til å komme i kontakt med andre medlemmer trenger du elite status.",
	"MY BENEFITS": "MINE FORDELER",
	"{discount} cheaper": "billigere {discount}",
	"save": "SPAR",
	"Create my profile": "Opprett profil",
	"Read messages": "Les meldinger",
	"Add to Favorites": "Legg til i favoritter",
	"Make yourself visible to other users by adding them to favorites": "Gjør deg selv synlig for andre brukere ved å legge dem til i dine favoritter",
	"Photo Album": "Fotoalbum",
	"No fake pictures allowed": "Ingen falske bilder er tillatt",
	"Album pictures": "Albumbilder",
	"Show yourself and increase your possibilities": "Vis deg frem og øk dine sjanser",
	"Detailed profile search": "Detaljert profilsøk",
	"Find exactly what you are looking for": "Finn nøyaktig det du leter etter",
	"See other user's pics": "Se andre brukeres bilder",
	"Choose your favorite": "Velg den du liker best",
	"Reply to and send messages": "Send eller svar på meldinger",
	"Make direct contact with up to five users per day": "Ta direkte kontakt med opp til fem brukere pr dag",
	"Be shown first in searches": "Bli vist først i søk",
	"Your profile will be featured before the rest of the users": "Din profil vil bli vist før andre profiler",
	"Make direct contact with unlimited users": "Ta direkte kontakt med ubegrenset antall brukere",
	"Choose payment method": "Velg betalingsmetode",
	"LESS THAN": "MINDRE ENN",
	"PER DAY": "PER DAG",
	"DISCRETION": "DISKRESJON",
	"Our website name will not be disclosed": "Vårt navn vises ikke på fakturaen",
	"Our name will not be disclosed": "Vårt navn vises ikke på fakturaen",
	"100% Anonymous.": "100% Anonymt.",
	"Choose your subscription": "Velg ditt abonnement",
	"Recommended": "Anbefalt",
	"Most Popular": "Mest populær",
	"Secure payments with": "Sikker betaling med",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Din melding ble ikke sendt. Du må oppgradere ditt abonnement for å kunne sende meldinger.",
	"Your purchase has been successful": "Ditt kjøp er vellykket gjennomført",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "For best mulig brukeropplevelse fornyes alle abonnement automatisk. Hvis du vil endre dette kan du gjøre det",
	"here.": "her.",
	"after_here": "",

	//ad
	"Become a": "Bli",
	"PREMIUM MEMBER": "PREMIUM MEDLEM",
	"And contact": "og kontakt",
	"more people": "flere brukere",

	"upgrade": "Oppgrader",
	"Boost": "Fremhev",
	"boost": "Fremhev",
	"your profile": "profilen din",
	"Start chatting and meet more people": "Start å chatte og treff flere menneksern",
	"And get": "Og få",
	"more views": "flere besøk",

	"Now +3 extra Credits with each purchase": "Nå 3 ekstra kreditter ved hvert kjøp",
	"Best Seller": "Mest solgt",
	"Select your Package": "Velg din pakke",
	"Less than {amount} per credit": "Mindre enn {amount} per kreditt",
	"Less than": "Mindre enn",
	"per credit": "per kreditt",
	"Free credits": "Gratis kreditter",
	"per purchase": "ved hvert kjøp",
	"Premium Status doesn’t allow free messages.": "Premium medlemskap gir deg ikke muligheten til å sende meldinger gratis.",
	"Chat now": "Chat nå",
	"Get Credits": "Få Kreditter",
	"Credit": "Kreditt",
	"Credits": "Kreditter",
	"My credits": "Mine kreditter",
	"{count} Credits left": "{count} Kreditter igjen",
	"Your message has not been sent, you need credits to be able to send messages.": "Din melding ble IKKE sendt. Du må ha kreditter for å kunne sende meldinger.",
	"Icebreaker": "Icebreaker",
	"Icebreaker today": "Icebreaker i dag",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Prisen for å sende en icebreaker de er to kreditter. En Icebreaker kan kun brukes for å starte en samtale eller som et svar på andre icebreakere.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Ved kjøp av en pakke tilegner brukeren seg en pakke med kreditter som brukes til å sende meldinger. Forholdet mellom kreditter per melding er 10 til 1. Det vil si, brukeren må ha minst ti kreditter i sin konto som vil bli brukt til å sende en melding. Prisen for å sende en icebreaker de er to kreditter.",

	"Trial pack": "Prøve pakke",
	"Only one trial pack per user allowed": "Kun en pakke tilgjengelig per bruker",
	"Used": "Brukt",

	"Exclusive Promotion": "Eksklusivt Tilbud",
	"Account Deleted": "Kontoen er Slettet",
	"Log out Successful": "Logg Ut",

	"Do you like FREE LIVE SEX?": "Liker du GRATIS LIVE SEX?",
	"Enter this code to enjoy a 10% discount:": "Nyt en 10% rabatt ved å bruke koden:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Denne koden er gyldig de neste 6 timene ved ditt første kjøpp på nettsiden.",

	"This account has been deleted.": "Denne kontoen har blitt slettet.",
	"Our algorithm has found a better option in line with your taste.": "Algoritmen vår har funnet et bedre alternativ i tråd med din smak.",
	"You will be redirected in a few seconds.": "Du blir videresendt om få sekunder.",

	"Has added you to favorites": "Har lagt deg til i sine favoritter",
	"Is looking at your profile": "Ser på profilen din",
	"See profile": "Se profil",
	"See message": "Se melding",

	"I think I am in love": "Jeg tror jeg er forelsket",
	"Hey! I'm winking at you": "Dette kysset er til deg",
	"Tell me more about you. Fancy a chat?": "Hei kjekken. Skal vi chatte?",
	"Some flowers for you!": "Blomster til deg!",
	"Sending you a kiss": "Kyss til deg",
	"Surprise, it\'s me!": "Overraskelse, det er meg!",
	"Hi, how are you?": "Hei. Hvordan går det?",
	"Let's get naughty": "Let's get naughty",

	"Get 5 times the regular response rate. Premium members benefits:": "Få 5 ganger så mange reaksjoner. Premium medlem fordeler:",
	"Appear high up on searches": "Du vises lengre frem på søk",
	"Distinctive badge that increases your visibility": "Egen logo som øker synligheten",
	"5 Free Icebreakers a day": "5 gratis Icebreaker om dagen",
	"In addition to this, a voucher for a 15% Webcam discount": "Ï tillegg til dette, en kupong for 15% rabatt på WebCam",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "En Icebreaker kan kun brukes for å starte en samtale eller som et svar på andre icebreakere.",

	"Succesful purchase": "Kjøp vellykket gjennomført",
	"Your purchase has been successful.": "Ditt kjøp var vellykket.",
	"It can take a few minutes to update.": "Det kan ta et par minutter før det oppdateres.",
	"You will be redirected.": "Du vil bli videreført.",

	"Changes can take some minutes to update.": "Det kan ta noen minutter før endringene oppdateres.",

	"Yearly Package": "Årspakke",
	"Package": "Pakke",
	"Buy Now": "Kjøp nå",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Elite-status lar Brukeren kontakte opptil 5 forskjellige brukere hver dag ved å sende dem ubegrensede meldinger.",

	"Exclusive offer": "Eksklusivt tilbud",
	"Welcome": "Velkommen",
	"Mary from {domain_name} support": "Heidi fra {domain_name} support",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Velkommen til {domain_name}. Vi håper du finner det du leter etter.",
	"Here are some Pro Tips to be more successful and meet people:": "Her har du noen tips for å hjelpe deg på veien:",
	"Upload a profile picture to get more connections.": "Last opp et bilde for å få flere kontakter.",
	"Send an icebreaker to get a conversation easily started.": "Send en icebreaker for å få samtalen i gang.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Hvis du ser noen du liker, ikke glem å legg de til i favoritter for å gjøre de oppmerksom på deg.",
	"And of course, if you like somebody send a message and ask for a date!": "Og selvfølgelig, hvis du liker noen, send en melding og be om en date!",
	"If you need any extra help, please contact our ": "Hvis du trenger hjelp, ta kontakt med ",
	"support": "Kundeservice",
	". We will be happy to help you.": " Vi hjelper deg med glede.",
	"Try your luck": "Prøv lykken",
	"64% of our users have a date in the first 2 weeks.": "64% av våre kunder har en date i løpet av de 2 første ukene.",
	"Good Luck!.": "Lykke til!",
	"*This message will be deleted upon closing.": "*Denne meldingen blir slettet når den lukkes.",

	"Private picture": "Privat bilde",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Private bilder vil ikke vises klart. Kun betalende brukere vil kunne se disse.",
	"Name change": "Endre navn",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Hvis du ønsker å bytte navn eller brukernavn, ta kontakt med oss og vår Kundeservice vil gjøre det innen 24 timer.",
	"See all images": "Se alle bilder",
	"Unlock all private photos forever for just 20 credits": "Lås opp private bilder for alltid for kun 20 kreditter",
	"Unlock forever all private pictures": "Lås opp private bilder for alltid",

	"This email address is not registered, please sign up to log in.": "Denne epost adressen finnes ikke i vårt system. Vennligst registrer deg før du kan logge deg inn.",
	"Congratulations, your account is now active.": "Gratulerer, kontoen din er aktivert.",
	"Complete one last step and have fun!": "Fullfør det siste steget og kos deg!",
	"OR": "ELLER",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Vi har sendt deg en epost med en aktiveringslink til kontoen din. Denne vil være gyldig i 12 timer:",
	"If you haven\'t received it in your inbox or ": "Hvis du ikke har mottatt den, verken i innboksen eller i ",
	"or you want to modify the email address, click on the button below.": "eller du vil endre epost adressen, klikk på linken nedenfor.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Vi har sendt deg en epost for å validere kontoen din. Eposten vil være gyldig de neste 12 timer:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Hvis du ikke kan finne den i verken inboxen eller spam folderen kan du endre eller sende den på nytt",
	"Resend or change email": "Send på nytt eller endre epost",
	"Please use the same email you registered.": "Please use the same email you registered.",
	"Connect with Google": "Koble til med Google",
	"Connect with Yahoo": "Sign up with Yahoo",
	"Connect with Microsoft": "Koble til med Hotmail",
	"Or turn back": "Go back",
	"Complete your registration": "Complete your registration",

	"It will not be shared": "Vil aldri deles",
	"It will never be shared. 100% Privacy": "Vil aldri deles. 100% anonymitet",

	"Unlock all private pictures forever.": "Lås opp private bilder for alltid.",
	"Unlock": "Lås opp",

	"You will be redirected in a second": "Du vil bli omdirigert om noen sekunder",

	"Card payment": "Kortbetaling",
	"Bank transfer": "Bankoverføring",
	"Prepaid card": " Forhåndsbetalt kort",

	"Error": "Feil",
	"page not found": "Kunne ikke finne siden",
	"Back to homepage": "Tilbake til hjemmesiden",

	"per month": "per måned",
	"week": "uke",
	"{count} week": "{count} uke",

	"Your profile has been blocked": "Din profil har blitt blokkert",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "For at vi kan tilby den best mulige service til alle våre kunder, blokkerer vi profiler som ikke har sendt en melding i løpet av 21 dager.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "For å låse opp din profil, og for å få tilgang til alle funksjoner på siden, må du kjøpe en kreditt-pakke.",
	"Restore access to:": "Gjenopprett tilgang til:",
	"All messages": "Alle meldinger",
	"Users search": "Søk etter brukere",
	"Your contacts": "Dine kontakter",
	"All favourites": "Alle favoritter",
	"All pictures": "Alle bilder",
	"Send Icebreakers": "Send Icebreakers",
	"Unblock Profile": "Lås opp profil",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Dette nettstedet bruker egne og tredjeparts informasjonskapsler og identifikatorer for å forbedre tilgjengeligheten, og for å analysere trafikken vår. Hvis du fortsetter å surfe, vurderer vi at du godtar bruken av dem. For mer informasjon, besøk vår",
	"Cookies Policy": "policy for informasjonskapsler",

	"DATE GUARANTEE": "DATE GARANTI",
	"Take advantage of our Date guarantee": "Dra nytte av dategarantien din for ekte dater, noe som betyr",
	"No date? Money Back!": "Ingen date? Pengene Tilbake!",
	"No risk": "Ingen risiko",
	"Guarantee of success": "Garantert suksess",
	"Terms and Conditions": "Vilkår",

	"Boost your visibility": "Øk synligheten din",
	"SAVE": "SPAR",
	"Add 3 months premium": "Tilføy 3 måneders premium",
	"Add {months} months premium": "Tilføy {months} måneders premium",

	"We guarantee you a real date.": "Vi garanterer at du vil finne en ekte date, det betyr.",
	"YES, I WANT A DATE": "JA, JEG ØNSKER EN DATE!",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Vilkår og betingelser for date-garantien. For å oppfylle alle refusjonsbetingelsene, må du ha hatt en fullstendig utfylt profil i minst seks måneder og din personlige beskrivelse i profilen din. Profilen din må inneholde minst ett godkjent bilde i løpet av denne perioden. Videre må du hver uke kontakte minst syv forskjellige medlemmer som befinner seg i nærheten av deg (i en radius på 100 km) innenfor ditt aldersområdet (-5 til +5 år). Hvis du oppfyller alle vilkårene som er nevnt ovenfor, og du fremdeles ikke har funnet en date, så vil vi fullt ut refundere alle betalinger du har gjort i denne tiden. Denne garantien gjelder kun 12 måndeder etter registrering. Lykke til!",

	"You have {count} days premium left": "Du har {count} dager igjen av ditt premium abonnement",
	"Check our special offer before deleting your account!": "Sjekk vårt spesialtilbud før du sletter din konto!",
	"Delete my account": "Slett min konto",
	"Free": "Gratis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Premium-, Elite- og VIP-pakker fornyes automatisk, noe som betyr at de vil automatisk fornyes når de går ut.",
	"and can confirm that I am 18 years or older.": "og jeg bekrefter at jeg er over 18 år.",

	"COMPLETE YOUR PURCHASE": "FULLFØR KJØPET DITT",
	"Payment details": "Betalingsdetaljer",
	"Total": "Totalt",
	"3-digit number on the back of your credit card": "3-sifret nummer på baksiden av ditt kort",
	"MM / YY": "MM / AA",
	"MM": "MM",
	"YY": "AA",
	"Name on card": "Navn på kort",
	"Charges will appear discreetly as tpmpsup.com": "Betalingene vil vises diskret som virtualcharges.com",
	"Buy Now": "Kjøp Nå",
	"Premium package 3 Months": "Premium pakke 3 måneder",

	"This purchase was declined. Please check your details and try again.": "Kjøpet ditt ble avslått. Vennligst sjekk detaljene dine og prøv på nytt.",
	"Your purchase was succesful.": "Kjøpet ditt var vellykket.",

	"SPECIAL OFFER": "SPESIALTILBUD",
	"Unlock your account with our special offer": "Lås opp kontoen din med vårt spesialtilbud",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Utnytt vårt spesialtilbud for å låse opp profilen din og gjenopprette alle funksjonene.",

	"card_number_require": "Kortnummer kreves",
	"card_number_invalid": "Kortnummeret er ugyldig",
	"card_name_require": "Kortholders navn kreves",
	"card_name_invalid": "Kortholders navn er ugyldig",
	"card_cvc_require": "CVC kreves",
	"card_cvc_invalid": "CVC er ugyldig",
	"card_expiry_require": "Kortets utløpsdato kreves",
	"card_expiry_invalid": "Kortets utløpsdato er ugyldig",

	"Private photo": "Privat bilde",
	"Search for someone now": "Søk etter noen nå",
	"Edit": "Redigere",

	"Locked Album": "Låst album",
	"Get credits to unlock all albums": "Få kreditter for å åpne albumet",
	"picture ::: pictures": "bilde ::: bilder",
	"Open": "Åpen",

	"Receive notifications": "Aktiver varsler",
	"I want to be notified when I receive a message": "Jeg ønsker varsel når jeg mottar en ny melding",
	"No": "Nei",
	"Yes": "Ja",

	"logout_confirm_title": "Logg av",
	"Are you sure you want to log out?": "Er du sikker på at du vil logge av?",

	"Your payment has been cancelled": "Betalingen din er kansellert",
	"Something went wrong, please try again in 10 minutes": "Noe gikk galt. Prøv igjen om 10 minutter",

	"Please accept the terms and conditions to continue": "Vennligst godta vilkårene og betingelsene for å fortsette",
	"By checking this box and clicking continue I accept the": "Ved å merke av i denne boksen og klikke på Fortsett godtar jeg",
	"rg_terms": "Vilkårene",
	"rg_privacy": "Personvernpolicyen",
	"rg_cookies": "Informasjonskapsler",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "Jeg er klar over bruken av fiktive profiler på nettstedet og bekrefter at jeg er myndig.",

	"Cookie": "Informasjonskapsel",
	"Responsible Party": "Ansvarlig part",
	"Category": "Kategori",
	"Cookie Name": "Navn",
	"Purpose": "Formål",
	"Lifespan": "Levetid",
	"Type of Cookie": "Type informasjonskapsel",
	"Strictly necessary": "Strengt nødvendig",
	"Functional": "Funksjonell",
	"Performance": "Prestasjon",
	"Advertising": "Advertising",
	"cookie-policy": "informasjonskapsel-policy",
	"cookie policy": "informasjonskapsel policy",

	"We Care About Your Privacy": "Vi bryr oss om ditt personvern",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Denne siden bruker egne, tekniske og tredje parts informasjonskapsler for å forbedre brukervennlighet, analysere brukerenes preferanser og for å hjelpe oss og våre markedsføringspartnere å analysere resultat av kampanjer og personlig reklame. Ved å klikke på \"aksepter alle\" aksepterer du bruk av alle informasjonskapsler.",
	"Accept All": "Aksepter alle",
	"Cookies Settings": "Innstillinger for informasjonskapsler",
	"About your privacy": "Om ditt personvern",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Vi bruker informasjonskapsler for å balansere trafikk, identifisere økter, analyser og markedsføring. Du kan velge å skru av og på dette fra panelet.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Du kan endre innstillinger når som helst på :cookies_policy siden.",
	"Accept all": "Akspeter alle",
	"Manage Consent Preferences": "Tilpass samtykke alternativer",
	"Technical (Mandatory)": "Teknisk (strengt nødvendig)",
	"Performance (Monitor website performance)": "Funksjonell (Forbedre brukeropplevelsen)",
	"Functional (User experience improvement)": "Ytelse (overvåke nettsidens ytelse)",
	"Advertising (Marketing measurement)": "Reklame (Markedsføringsanalyse)",
    "Advertising (Personalization)": "Annonsering (Tilpasning)",
	"Save Selection": "Lagre valg",
	"Reject All": "Avvis alle",

	"We and our partners process data to provide:": "Vi og våre partnere behandler data for å gi:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Bruk nøyaktige geolokasjonsdata. Skann enhetsegenskaper aktivt for identifikasjon. Lagre og/eller få tilgang til informasjon på en enhet. Personlig tilpassede annonser og innhold, måling av annonser og innhold, publikumsinnsikt og produktutvikling.",
	"List of partners": "Liste over partnere",
	"Cookie Policy": "Informasjonskapsel policy",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Disse informasjonskapslene brukes i forbindelse med sporing av handlinger realtert til reklamer. For eksempel vil disse informasjonskapslene huske at du har besøkt {name} og kan brukes til å vise deg en personlig annonse på en annen nettside. Vennligst sjekk ut følgene linker for de plattformene hvor vi promoterer oss selv, Google, Microsoft og Twitter for mer informasjon.",
	"Delete cookies": "Cookies",
	"Save selection": "Lagre valg",
	"Reject all": "Avvis alle",

	"{name} hasn't replied to you yet. Send another message!": "{name} har ikke svart deg enda. Send en melding til!",
	"Validate my Ice-breaker": "Bekreft min icebreaker",

	"Distance": "Avstand",
	"now": "nå",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Fra og med 1. juni 2023, og fremover, vil vi ikke behandle betalinger med PaySafeCard",

	"Dear User,": "Kjære bruker,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Takk for din interesse for å bli med på nettstedet vårt, men for å holde en kjønnsbalanse nær 50/50 menn og kvinner, er registrering ikke tillatt for menn for øyeblikket.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Hvis du fortsatt vil ha en date, klikk på knappen nedenfor og prøv en av de beste nettsidene på nettet.",
	"Try Now": "Prøv nå",

	"Your account has been deleted.": "Kontoen din har blitt slettet.",
	"According to your choices, our algorithms have found a better option for you.": "I henhold til dine valg har algoritmene våre funnet et bedre alternativ for deg.",
	"See More": "Se mer",

	"10 credits for you!": "10 kreditter til deg!",
	"Get 3 free Icebreakers": "Få 3 Icebreakers gratis",
	"Free Icebreakers": "Icebreakers gratis",
	"Congratulations, you have received 3 Icebreakers!": "Gratulerer, du har mottatt 3 Icebreakers!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay virker bare med Safari nettleser. Vennligst åpne din Safari nettleser og logg inn på din konto for å gå videre med denne betalingen.",

	"No nudity": "Ingen nakenhet",
	"Nudity allowed": "Nakenhet tillatt",

	"Edit profile": "Redigere profil",
	"Online": "Pålogget",
	"Step": "Neste",
	"Completed": "Fullført",
	"Help": "Hjelp",
	"or Drag and drop": "eller dra & slipp",

	"You will be able to send messages in": "Du vil kunne sende meldinger om",
	"Send": "Send",
	"Success": "Vellykket",
	"Start a conversation with {name}": "Start en samtale med {name}",
	"Failed": "Mislykket",
	"Congratulations, your account has been successfully created.": "Gratulerer, din konto er opprettet.",
	"Contact our support": "Kontakt vår support",

	"About": "Om",
	"Scale": "Juster",
	"Rotate": "Roter",

	"No favorites added": "Ingen favoritter lagt til",

	"Enter Email": "Skriv inn Epost",

    "We have sent you an email to:": "Vi har sent en epost til:",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Vennligst bekreft ved å klikke på \"slett konto\" knappen i eposten, og kontoen din vil bli slettet permament fra vårt system.",
    "DELETE ACCOUNT": "Slett konto",

  "Didn\'t find what you were looking for?": "Fant du ikke det du søkte etter?",
	"Please send us an email and we will get back to you.": "Vennligst send oss en epost og vi vil kontakte deg.",
	"Or contact us using the form": "Eller kontakt oss ved å bruke skjemaet.",

	"BOOST YOUR ACCOUNT": "GI KONTOEN DIN EN BOOST",
	"We will send you an email to boost your account:": "Vi vil sende deg en mail for å forbedre din konto:",
	"Get Free exclusive benefits": "Få gratis eksklusive fordeler",
	"Premium Status 24h": "Premium status 24 timer",
	"5 Free Credits": "5 Gratis kreditter",
	"3 Free Icebreakers": "3 Gratis icebreakers",
	"IMPROVE ACCOUNT": "FORBEDRE KONTOEN",

	"COMPLETE REGISTRATION": "FULLFØR REGISTRERING",
	"Confirm your email and complete registration": "Bekreft eposten din og fullfør registreringen",
	"Benefits": "Fordeler",
	"More Visibility": "Bedre synlighet",
	"Send Messages": "Send meldinger",

    "Your registration is completed": "Registreringen er fullført",

}
